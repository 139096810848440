import { ErrorLocal, ApiError, HttpStatusCode } from '@appTypes/api';
import axios from 'axios';

type Error = ApiError | unknown;

const isApiError = (error: Error): error is ApiError => (error as ApiError).response !== undefined;

export const prepareError = (error: Nullable<Error | undefined>): ErrorLocal => {
  if (!error) return null;

  if (axios.isAxiosError(error)) {
    return {
      status: error.response?.status || HttpStatusCode.INTERNAL_SERVER_ERROR,
      statusText: error.response?.statusText || 'INTERNAL_SERVER_ERROR',
      message: error.response?.data.detail ? error.response?.data.detail : error.message,
    };
  }

  if (!isApiError(error)) return null;

  const {
    status,
    statusText,
    data: { detail },
  } = error.response;

  const message = (() => {
    if (typeof detail === 'string') {
      return detail;
    }

    if (Array.isArray(detail)) {
      return detail[0]?.msg || '';
    }

    return '';
  })();

  return {
    status,
    statusText,
    message,
  };
};
