import { ApiError } from '@appTypes/api';
import {
  DefaultFilters,
  RequestState,
  SetErrorActionPayload,
  UpdateFilterActionPayload,
  DefaultRequestState,
  DefaultItem,
} from '@appTypes/helpers/redux';
import { prepareError } from '@utils/data/prepareError';
import { defaultRequestState } from './defaultRequestState';

interface IReducerHandlerDefault<TItem> extends DefaultRequestState {
  filters: DefaultFilters;
  fetchedPages: number[];
  current?: {
    data: Nullable<TItem>;
  } & DefaultRequestState;
  create?: DefaultRequestState;
  update?: DefaultRequestState;
  delete?: DefaultRequestState;
}

export const createReducerHandler = <
  TState extends IReducerHandlerDefault<TItem>,
  TItem extends DefaultItem,
  TSimpleItem extends DefaultItem = TItem,
>(
  name: keyof TState,
  state: TState,
) => {
  type ItemWPage = WPage<TSimpleItem>;

  return {
    setRequestLoading(payload: RequestState) {
      if (!state[payload]) {
        throw new Error('Request field not included in initial state');
      }

      return {
        ...state,
        [payload]: {
          ...state[payload],
          error: null,
          loading: true,
        },
      };
    },
    setRequestError(payload: SetErrorActionPayload) {
      if (!state[payload.field] === undefined) {
        throw new Error('Request field not included in initial state');
      }

      return {
        ...state,
        [payload.field]: {
          ...state[payload.field],
          error: prepareError(payload.error),
          loading: false,
        },
      };
    },
    updateFilter<T extends UpdateFilterActionPayload<any>>(payload: T) {
      if (Array.isArray(payload)) {
        const filters = (payload as [keyof DefaultFilters, number][]).reduce(
          (acc, [filterName, value]) => ({
            ...acc,
            [filterName]: value,
          }),
          {} as DefaultFilters,
        );

        return {
          ...state,
          [name]: [],
          fetchedPages: [],
          current: {
            data: null as Nullable<TItem>,
            ...defaultRequestState,
          },
          filters: {
            ...state.filters,
            ...filters,
            page: 1,
          },
        };
      }

      if (payload.filterName === 'page') {
        return {
          ...state,
          current: {
            data: null as Nullable<TItem>,
            ...defaultRequestState,
          },
          filters: {
            ...state.filters,
            page: payload.value,
          },
        };
      }

      return {
        ...state,
        [name]: [],
        fetchedPages: [],
        filters: {
          ...state.filters,
          [payload.filterName]: payload.value,
          page: 1,
        },
      };
    },
    request() {
      return {
        ...state,
        error: null,
        loading: true,
      };
    },
    requestSuccess(payload: { items: TSimpleItem[]; itemsCount: number; page: number }) {
      const { itemsCount, page, items: newItems } = payload;
      const currentItems = (state[name] as unknown as ItemWPage[]) || [];
      const items = newItems.map((item) => ({
        ...item,
        _page: page,
      }));
      const newFetchedPages =
        state.fetchedPages.includes(page) || page === 0
          ? state.fetchedPages
          : [...state.fetchedPages, page];

      return {
        ...state,
        loading: false,
        fetchedPages: newFetchedPages,
        [name]: [...currentItems, ...items],
        itemsCount,
        filters: {
          ...state.filters,
          page: page === 0 ? state.filters.page : page,
        },
      };
    },
    requestFailed(payload: ApiError) {
      return {
        ...state,
        fetchedPages: [],
        loading: false,
        error: prepareError(payload),
      };
    },
    getCurrent(payload: TItem) {
      return {
        ...state,
        current: {
          data: payload,
          loading: false,
          error: null,
        },
      };
    },
    clearCurrent() {
      return {
        ...state,
        current: {
          data: null as Nullable<TItem>,
          ...defaultRequestState,
        },
      };
    },
    createSuccess() {
      return {
        ...state,
        create: defaultRequestState,
        fetchedPages: [],
        [name]: [],
      };
    },
    updateSuccess(payload: TItem) {
      const {
        filters: { page },
      } = state;

      const updatedNotificationRule = Object.entries(payload).reduce(
        (acc, [key, val]) => {
          if (Array.isArray(val)) return acc;

          return {
            ...acc,
            [key]: val,
          };
        },
        { _page: page },
      );

      const notificationRuleMap = (notificationRule: ItemWPage) =>
        notificationRule.id === payload.id ? updatedNotificationRule : notificationRule;

      return {
        ...state,
        update: defaultRequestState,
        [name]: ((state[name] as unknown as ItemWPage[]) || []).map(notificationRuleMap),
      };
    },
    deleteSuccess() {
      const currentPage = state.filters.page;
      const previousPage = currentPage - 1 || 1;

      const isLastElementOnPage = ((state[name] as unknown as ItemWPage[]) || []).filter(
        (notificationRule) => notificationRule._page === currentPage,
      );

      return {
        ...state,
        filters: {
          ...state.filters,
          page: isLastElementOnPage ? previousPage : currentPage,
        },
        delete: defaultRequestState,
        fetchedPages: [],
        [name]: [],
      };
    },
  };
};
