import { PropsWithChildren, useEffect, useState } from 'react';
import { useGetUserQuery, useUpdateFeatureFlagsMutation } from '@apiRtk/users';
import { FEATURE_FLAGS } from '@appTypes/common';
import { CenterX, FlexColumn, PaperLight } from '@components/LayoutUtils';
import { PageWrapper } from '@components/PageWrapper/PageWrapper';
import CONFIG from '@config';
import { useCurrentUserInfo } from '@hooks';
import { SvgIconComponent, Person, AdminPanelSettingsRounded } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import StorageIcon from '@mui/icons-material/Storage';
import { Chip, Switch, Typography } from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import {
  isFeatureFlagEnabled,
  disableFeatureFlag,
  enableFeatureFlag,
  getVisibleFeatureFlags,
} from '@services/featureFlags';
import { notificationService } from '@services/notificationService/notificationService';
import { createFullName } from '@utils/data/createFullName';
import { t } from '@utils/translate';
import { getEnvironment, getFormattedTimeZone } from '@utils/utils';
import { useSelector } from 'react-redux';

const InfoCard = ({
  title,
  IconComponent,
  children,
}: PropsWithChildren<{ title: string; IconComponent: SvgIconComponent }>) => (
  <PaperLight sx={{ p: 2 }}>
    <CenterX gap={1} pb={1}>
      <IconComponent color="secondary" />
      <Typography variant="h6">{title}</Typography>
    </CenterX>
    {children}
  </PaperLight>
);

const InfoRow = ({
  label,
  value,
  testId,
}: {
  label: string;
  value: string | number;
  testId: string;
}) => (
  <CenterX gap={1} py={0.3}>
    <Typography variant="body1" sx={{ fontWeight: 'bold', minWidth: '80px' }}>
      {label}:
    </Typography>
    <Typography variant="body1" data-testid={testId}>
      {value}
    </Typography>
  </CenterX>
);

const AdminTools = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const { isWattstorUser } = useCurrentUserInfo();

  if (!currentUser || !isWattstorUser) {
    return null;
  }

  const appInfo = [
    { label: t('labelVersion'), value: CONFIG.APP_VERSION, testId: 'app-version' },
    {
      label: t('labelEnvironment'),
      value: getEnvironment().toUpperCase(),
      testId: 'app-env',
    },
    {
      label: t('labelTimezone'),
      value: getFormattedTimeZone(),
      testId: 'app-timezone',
    },
  ];

  const userInfo = [
    { label: t('labelUserId'), value: currentUser?.id, testId: 'user-id' },
    { label: t('headerEmail'), value: currentUser?.email, testId: 'user-e-mail' },
    {
      label: t('labelName') ?? t('labelName'),
      value: createFullName(currentUser.first_name, currentUser.last_name),
      testId: 'user-name',
    },
    {
      label: t('labelIsActive'),
      value: currentUser?.is_active ? t('labelYes') : t('labelNo'),
      testId: 'user-is-active',
    },
    { label: t('labelUserRole'), value: currentUser?.role?.name, testId: 'user-role' },
  ];

  return (
    <PageWrapper title={t('headingAdminTools')} headerIcon={AdminPanelSettingsRounded}>
      <FlexColumn gap={2}>
        <InfoCard title={t('headingApplication')} IconComponent={InfoOutlinedIcon}>
          {appInfo.map((info) => (
            <InfoRow {...info} key={info.testId} />
          ))}
        </InfoCard>

        <InfoCard title={t('headingLoggedUser')} IconComponent={Person}>
          {userInfo.map((info) => (
            <InfoRow {...info} key={info.testId} />
          ))}
        </InfoCard>

        <FeatureFlagsListFrontend />
        <FeatureFlagsListBackend />
      </FlexColumn>
    </PageWrapper>
  );
};

function FeatureFlagsListFrontend() {
  const visibleFeatureFlags = getVisibleFeatureFlags();
  const frontendFeatures = visibleFeatureFlags.filter((feat) => !feat.backendOnly);

  const [flagStates, setFlagStates] = useState(() => {
    const initialStates: { [key in FEATURE_FLAGS]: boolean } = {} as any;
    frontendFeatures.forEach((flag) => {
      initialStates[flag.name] = isFeatureFlagEnabled(flag.name);
    });
    return initialStates;
  });

  const handleToggle = (flagName: FEATURE_FLAGS) => {
    if (isFeatureFlagEnabled(flagName)) {
      disableFeatureFlag(flagName);
    } else {
      enableFeatureFlag(flagName);
    }

    setFlagStates((prevStates) => ({
      ...prevStates,
      [flagName]: !prevStates[flagName],
    }));
  };

  return frontendFeatures.length ? (
    <InfoCard title={t('headingFeatures')} IconComponent={SettingsSuggestIcon}>
      {frontendFeatures.map(({ name, label, environments }) => (
        <CenterX key={name}>
          <Switch
            checked={flagStates[name]}
            color="secondary"
            onChange={() => handleToggle(name)}
          />
          <Typography variant="body1">{t(label)}</Typography>

          {environments.map((env) => (
            <Chip
              label={env}
              key={`${name}-${env}`}
              variant="outlined"
              color={env === 'development' ? 'success' : 'primary'}
              size="small"
              sx={{ ml: 1 }}
            />
          ))}
        </CenterX>
      ))}
    </InfoCard>
  ) : null;
}

function FeatureFlagsListBackend() {
  const visibleFeatureFlags = getVisibleFeatureFlags();
  const backendFeatures = visibleFeatureFlags.filter((feat) => feat.backendOnly);

  const [updateFeatureFlags, { isLoading }] = useUpdateFeatureFlagsMutation();
  const { currentUser } = useCurrentUserInfo();
  const userId = currentUser?.id;

  const { data } = useGetUserQuery(userId!, { skip: !userId });
  const backendFlags = data?.flags;

  const [flagStates, setFlagStates] = useState<{ [key: string]: boolean }>({});

  useEffect(() => {
    if (backendFlags) {
      setFlagStates(backendFlags);
    }
  }, [backendFlags]);

  const handleToggle = async (flagName: FEATURE_FLAGS) => {
    const previousValue = flagStates[flagName];
    const newStates = { ...flagStates, [flagName]: !previousValue };

    setFlagStates(newStates);

    if (userId) {
      try {
        await updateFeatureFlags({ userId, flags: newStates }).unwrap();
      } catch (error) {
        notificationService.error(t('errorUpdateBackendFeatureFlag'));
        // Revert the toggle back to its previous value.
        setFlagStates((prev) => ({ ...prev, [flagName]: previousValue }));
      }
    }
  };

  if (!backendFeatures.length) {
    return null;
  }

  return (
    <InfoCard title={t('headingBackendFeatures')} IconComponent={StorageIcon}>
      {backendFeatures.map(({ name, label, environments }) => (
        <CenterX key={name}>
          <Switch
            checked={!!flagStates[name]}
            color="secondary"
            onChange={() => handleToggle(name)}
            disabled={isLoading}
          />
          <Typography variant="body1">{t(label)}</Typography>

          {environments.map((env) => (
            <Chip
              label={env}
              key={`${name}-${env}`}
              variant="outlined"
              color={env === 'development' ? 'success' : 'primary'}
              size="small"
              sx={{ ml: 1 }}
            />
          ))}
        </CenterX>
      ))}
    </InfoCard>
  );
}

export default AdminTools;
