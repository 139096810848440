import { ApiError } from '@appTypes/api';
import { put, call } from 'redux-saga/effects';
import { UpdateParams } from './types';

export function* update<TItem, TItemUpdate>({
  payload,
  setRequestError,
  onSuccess,
  apiCall,
  setRequestLoading,
  clearCurrentActionName,
  cancelCurrentCleanup,
}: UpdateParams<TItem, TItemUpdate>) {
  try {
    yield put(setRequestLoading('update'));
    const data: TItem = yield call(apiCall, payload);
    yield put(onSuccess(data));

    if (clearCurrentActionName && !cancelCurrentCleanup) {
      yield put({ type: clearCurrentActionName });
    }
  } catch (error) {
    yield put(setRequestError({ field: 'update', error: error as ApiError }));
    throw error;
  }
}
