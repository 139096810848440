import { ButtonPrimaryOutlined } from '@components/Buttons/Buttons';
import { CenterX } from '@components/LayoutUtils';
import { ReactComponent as ErrorIllustration } from '@images/error-fatal.svg';
import BackIcon from '@mui/icons-material/ArrowBackRounded';
import WarningIcon from '@mui/icons-material/RefreshRounded';
import RefreshIcon from '@mui/icons-material/ReplayRounded';
import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { t } from '@utils/translate';
import { space } from '@utils/utils';

const ErrorContainer = styled(CenterX)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  padding: space(4),
  gap: 50,
  borderRadius: space(1),
}));

const ErrorContent = styled(Box)`
  text-align: left;
`;

type ErrorMessageProps = {
  errorCode: string;
  title: string;
  description: string;
  buttonText: string;
  buttonAction: () => void;
  buttonIcon: React.ReactNode;
};

type ErrorBannerProps = {
  errorCode: 500 | 404 | 403 | 401;
};

const ErrorMessage = ({
  errorCode,
  title,
  description,
  buttonText,
  buttonAction,
  buttonIcon,
}: ErrorMessageProps) => (
  <ErrorContainer flexDirection={{ xs: 'column', sm: 'row' }}>
    <ErrorIllustration width={218} height={180} />
    <ErrorContent>
      <CenterX gap={1}>
        <WarningIcon color="error" />
        <Typography variant="subtitle1" color="error">
          {errorCode}
        </Typography>
      </CenterX>
      <Typography variant="h4">{title}</Typography>
      <Typography variant="body1" mt={2}>
        {description}
      </Typography>
      <ButtonPrimaryOutlined
        size="small"
        onClick={buttonAction}
        sx={{ mt: 5, textTransform: 'uppercase' }}
        startIcon={buttonIcon}
      >
        {buttonText}
      </ButtonPrimaryOutlined>
    </ErrorContent>
  </ErrorContainer>
);

const ErrorBanner = ({ errorCode }: ErrorBannerProps) => {
  const handleGoBack = () => {
    window.history.back();
  };

  const handleRefresh = () => {
    window.location.reload();
  };

  const errorMessages: Record<ErrorBannerProps['errorCode'], ErrorMessageProps> = {
    500: {
      errorCode: t('error500Code'),
      title: t('error500Title'),
      description: t('error500Description'),
      buttonText: t('error500ButtonText'),
      buttonAction: handleRefresh,
      buttonIcon: <RefreshIcon />,
    },
    404: {
      errorCode: t('error404Code'),
      title: t('error404Title'),
      description: t('error404Description'),
      buttonText: t('error404ButtonText'),
      buttonAction: handleGoBack,
      buttonIcon: <BackIcon />,
    },
    403: {
      errorCode: t('error403Code'),
      title: t('error403Title'),
      description: t('error403Description'),
      buttonText: t('error403ButtonText'),
      buttonAction: handleGoBack,
      buttonIcon: <BackIcon />,
    },
    401: {
      errorCode: t('error401Code'),
      title: t('error401Title'),
      description: t('error401Description'),
      buttonText: t('error401ButtonText'),
      buttonAction: handleGoBack,
      buttonIcon: <BackIcon />,
    },
  };

  const errorContent = errorMessages[errorCode] || errorMessages[500];

  return (
    <ErrorMessage
      errorCode={errorContent.errorCode}
      title={errorContent.title}
      description={errorContent.description}
      buttonText={errorContent.buttonText}
      buttonAction={errorContent.buttonAction}
      buttonIcon={errorContent.buttonIcon}
    />
  );
};

export default ErrorBanner;
