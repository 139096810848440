import { AnalyticsDto, SiteDto } from '@appTypes/models/site.dto';
import { coreApi } from './apiWattstor';

type AnalyticsArgs = {
  items: AnalyticsDto[];
  siteId: SiteDto['id'];
};

type DeleteGraphArgs = {
  siteId: SiteDto['id'];
  graphName: string;
};

const analyticsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getAnalytics: builder.query<AnalyticsDto[], AnalyticsArgs['siteId']>({
      query: (siteId) => `sites/${siteId}/analytics`,
      providesTags: ['Analytics'],
    }),
    updateOrder: builder.mutation<AnalyticsDto[], AnalyticsArgs>({
      query: ({ siteId, items }) => ({
        url: `sites/${siteId}/analytics`,
        method: 'PUT',
        data: items,
      }),
      invalidatesTags: () => ['Analytics'],
    }),
    deleteGraph: builder.mutation<AnalyticsDto[], DeleteGraphArgs>({
      query: ({ siteId, graphName }) => ({
        url: `sites/${siteId}/analytics/graph/${graphName}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => ['Analytics'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetAnalyticsQuery, useUpdateOrderMutation, useDeleteGraphMutation } =
  analyticsApi;
