import { useEffect, useState } from 'react';
import { FormValuesDataExportNew, FormValuesNewCustomGraph } from '@appTypes/forms';
import { Node } from '@appTypes/models/site.dto';
import CheckboxTree from '@components/CheckboxTree/CheckBoxTree';
import { CenterX } from '@components/LayoutUtils';
import { SearchInput } from '@components/SearchInput';
import { Box, FormControlLabel, Switch } from '@mui/material';
import { t } from '@utils/translate';
import { UseFormSetValue, UseFormWatch } from 'react-hook-form';

type DevicesTreeBaseProps = {
  devices: Node[];
  setValue: UseFormSetValue<FormValuesNewCustomGraph>;
  watch: UseFormWatch<FormValuesNewCustomGraph>;
  loading?: boolean;
};

export const DevicesTree = ({ devices, setValue, watch, loading }: DevicesTreeBaseProps) => {
  const [searchText, setSearchText] = useState('');

  const selectedDevices = devices;
  const selectedIds = watch('metrics');

  const handleOnChange = (updatedIds: string[]) => {
    setValue('metrics', updatedIds);
  };

  return (
    <Box>
      <CenterX gap={2} flex={1}>
        <SearchInput
          size="small"
          debounceTime={0}
          value={searchText}
          onValueChange={(v) => setSearchText(v)}
          sx={{ flex: 1 }}
        />
      </CenterX>
      <CheckboxTree
        data={selectedDevices}
        selectedIds={selectedIds}
        onChange={handleOnChange}
        searchText={searchText}
        loading={loading}
      />
    </Box>
  );
};

type DevicesTreeAdvancedProps = {
  setValue: UseFormSetValue<FormValuesDataExportNew>;
  watch: UseFormWatch<FormValuesDataExportNew>;
  onToggleSwitch?: (checked: boolean) => void;
  devices?: Node[];
  loading?: boolean;
};

export const DevicesTreeAdvanced = ({
  devices,
  setValue,
  watch,
  onToggleSwitch,
  loading,
}: DevicesTreeAdvancedProps) => {
  const [searchText, setSearchText] = useState('');
  const [advancedMode, setAdvancedMode] = useState(false);

  const selectedIds = watch('metrics');

  const handleOnChange = (updatedIds: string[]) => {
    setValue('metrics', updatedIds);
  };

  const handleAllMetricsSwitch = (checked: boolean) => {
    setAdvancedMode(checked);
    setValue('metrics', []);
    onToggleSwitch?.(checked);
  };

  useEffect(() => {
    setSearchText('');
  }, [advancedMode]);

  return (
    <Box>
      <CenterX gap={2} flex={1}>
        <SearchInput
          size="small"
          debounceTime={0}
          value={searchText}
          onValueChange={(v) => setSearchText(v)}
          sx={{ width: '218px', mr: 1 }}
        />
        <FormControlLabel
          sx={{ flex: 1 }}
          control={
            <Switch
              size="small"
              checked={advancedMode}
              onChange={(_, checked) => handleAllMetricsSwitch(checked)}
            />
          }
          label={t('labelAllRawDataAvailable')}
        />
      </CenterX>

      <CheckboxTree
        data={devices}
        selectedIds={selectedIds}
        onChange={handleOnChange}
        searchText={searchText}
        loading={loading}
      />
    </Box>
  );
};
