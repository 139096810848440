import { useGetOverviewPanelQuery, useGetSiteQuery } from '@apiRtk/sites';
import { FEATURE_FLAGS } from '@appTypes/common';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { CenterY, GapY } from '@components/LayoutUtils';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import { useRedirect } from '@hooks';
import LineChartIcon from '@mui/icons-material/QueryStats';
import { Box, Alert, Typography, Button, Grid } from '@mui/material';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { formatDate } from '@utils/data/formatDate';
import { t } from '@utils/translate';
import { useParams } from 'react-router-dom';
import EnergyFlow from './EnergyFlow';
import PerformanceOverTime from './PerformanceOverTime/PerformanceOverTime';
import SiteOverviewPanel from './SiteOverviewPanel/SiteOverviewPanel';

const Overview = () => {
  const params = useParams();
  const siteId = parseInt(params.id as string, 10);
  const redirect = useRedirect();

  const {
    data: siteData,
    isLoading: siteLoading,
    isFetching: siteFetching,
    error: siteError,
  } = useGetSiteQuery(siteId, {
    skip: !siteId,
  });

  const {
    refetch: refetchOverview,
    data: overviewData,
    isLoading: overviewLoading,
    isError: overviewError,
  } = useGetOverviewPanelQuery(siteId, {
    skip: !siteId,
  });

  const firstSiteEcd = siteData?.imps[0]?.id;

  const title = siteData?.name ? siteData.name : t('headingSiteOverview');

  if (siteError || !siteId) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName={t('headingSiteOverview')}>
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {t('errorNoData')}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  if (siteLoading || siteFetching) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  if (!firstSiteEcd) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName={title}>
        <Box p={5}>
          <Alert variant="filled" color="warning">
            {t('errorNoEcdFound')}
          </Alert>
        </Box>
      </SiteHeaderWrapper>
    );
  }

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={title}>
      <Typography variant="h5" pt={4} pb={2}>
        {t('headingSystemStatus')}
      </Typography>

      {isFeatureFlagEnabled(FEATURE_FLAGS.SITE_OVERVIEW_PANEL) ? (
        <Grid container alignItems="center">
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={4}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {overviewError && (
              <AlertWithRefetch severity="error" onRetryClick={refetchOverview}>
                {t('errorCommon')}
              </AlertWithRefetch>
            )}
            {(overviewLoading || !overviewData) && !overviewError ? <Spinner /> : null}
            {overviewData ? (
              <SiteOverviewPanel
                acPower={750000}
                battery={overviewData?.batteryCapacity}
                systemDesign={overviewData?.coupling || ''}
                commissioning_date={
                  overviewData?.commissioningDate
                    ? formatDate(overviewData?.commissioningDate, 'MMMM yyyy')
                    : 'N/A'
                }
                renewables="1500 kW DC Solar"
                latitude={siteData.coordinates.lat}
                longitude={siteData.coordinates.lon}
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={8}>
            <EnergyFlow ecdId={firstSiteEcd} />
          </Grid>
        </Grid>
      ) : (
        <EnergyFlow ecdId={firstSiteEcd} />
      )}

      <PerformanceOverTime siteId={siteId} />

      <GapY size={2} />

      <CenterY pt={2}>
        <Button
          variant="contained"
          disableElevation
          startIcon={<LineChartIcon />}
          type="button"
          onClick={() => {
            redirect((paths) => paths.siteDataAnalysis(siteId));
          }}
        >
          {t('buttonGoToDataAnalysis')}
        </Button>
      </CenterY>
    </SiteHeaderWrapper>
  );
};

export default Overview;
