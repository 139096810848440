import { UserDto, UserSimpleDto } from '@appTypes/models/user.dto';
import { GetAllDto } from './common.dto';
import { EcdSimpleDto } from './ecd.dto';
import { OrganizationDto } from './organization.dto';

export interface CreateSiteDto {
  name: string;
  street: string;
  city: string;
  postal_code: string;
  country: string;
  lat: number;
  lon: number;
  organization_id: Nullable<OrganizationDto['id']>;
  commissioning_date?: string;
}

export interface UpdateSiteDto extends CreateSiteDto {
  siteId: SiteDto['id'];
}

export interface MoveSiteToOrganizationDto {
  siteId: SiteDto['id'];
  organization_id: Nullable<OrganizationDto['id']>;
}

export interface SiteDto {
  id: number;
  name: string;
  creation_date: string;
  last_modify_date: string;
  address: {
    street: string;
    city: string;
    postal_code: string;
    county: string;
    country: string;
  };
  coordinates: {
    lat: number;
    lon: number;
  };
  organization: Nullable<Omit<OrganizationDto, 'sites' | 'users'>>;
  imps: EcdSimpleDto[];
  users: (UserSimpleDto & { site_role: string })[];
  commissioning_date?: string;
}

export type SiteSimpleDto = Omit<SiteDto, 'imps' | 'users'>;

export interface SiteIdName {
  id: SiteDto['id'];
  name: SiteDto['name'];
}

export enum EcdDevicesPermissions {
  VIEW = 'view',
  BASIC_EDIT = 'basic_edit',
  ADVANCED_EDIT = 'advanced_edit',
}

export interface UpdateSiteUserDto {
  id: SiteDto['id'];
  user_id: UserDto['id'];
  devices_permissions: EcdDevicesPermissions;
}

export interface SitesDto extends GetAllDto {
  sites: SiteDto[];
}

export type ExportUnit = 'kW' | 'MW' | 'kWh' | 'MWh';
export type ExportMultiplier = 'none' | 'kilo' | 'mega' | 'giga';
export type ExportGranularity = '1m' | '10m' | '15m' | '30m' | '1h' | '24h';
export type ExportDataFormat = 'xlsx' | 'csv';

export type ExportSeries =
  | 'siteLoad'
  | 'gridConsumption'
  | 'gridBackflow'
  | 'renewableGeneration'
  | 'batteryCharging'
  | 'batteryDischarging';

export interface ExportDataPayload {
  siteId: SiteDto['id'];
  start: Date;
  end: Date;
  unit: ExportUnit;
  granularity: ExportGranularity;
  series: ExportSeries[];
  format: ExportDataFormat;
}

export interface ExportDataPayloadNew {
  siteId: SiteDto['id'];
  start: Date;
  end: Date;
  unit: ExportMultiplier;
  granularity: ExportGranularity;
  series: string[];
  format: ExportDataFormat;
}

export interface SiteOptimizationDto {
  enabled: boolean;
  optimizationType: string;
  optimizationScope: string;
  optimizationCurrency: string;
}

export interface UpdateOptimizationPayload extends SiteOptimizationDto {
  siteId: SiteDto['id'];
}

export type Node = {
  id: string;
  name: string;
  children: { id: string; name: string }[];
};

export interface UpdateUserPermissionsPayload {
  siteId: SiteDto['id'];
  user_id: UserDto['id'];
  device_permission: EcdDevicesPermissions;
}

/* Data analytics */
export enum GraphType {
  CUSTOM = 'custom',
}

type AnalyticsActions = {
  get: string;
  edit: Nullable<string>;
  delete: Nullable<string>;
};

export type AnalyticsDto = {
  graphId: string;
  title: string;
  graphType: string;
  seriesIds: Nullable<string[]>;
  actions: AnalyticsActions;
};

type YAxis = {
  unit: string;
  min: number;
  max: number;
};

type DataPoint = {
  name: string;
  value: [string, number];
};

type Series = {
  isEmpty: boolean;
  label: string;
  uniqueName: string;
  yaxisIndex: number;
  seriesMin: number;
  seriesMax: number;
  dataPoints: DataPoint[];
};

export type GraphDto = {
  yaxes: YAxis[];
  series: Series[];
  emptySeries: string[];
};

export type OverviewPanelDto = {
  commissioningDate: Nullable<Date>;
  batteryCapacity: Nullable<number>;
  coupling: Nullable<string>;
};
