import { usePalette } from '@hooks';

type LoaderProps = React.SVGProps<SVGSVGElement> & {
  size?: number;
  thickness?: number;
};

const Loader = ({ size = 32, thickness = 4, ...props }: LoaderProps) => {
  const currentPalette = usePalette();
  const center = size / 2;
  // Ensure the stroke is fully visible by subtracting half the thickness
  const radius = center - thickness / 2;
  const circumference = 2 * Math.PI * radius;

  return (
    <svg width={size} height={size} viewBox={`0 0 ${size} ${size}`} {...props}>
      {/* Background circle */}
      <circle
        cx={center}
        cy={center}
        r={radius}
        stroke={currentPalette.common.white}
        strokeWidth={thickness}
        fill="none"
      />
      {/* Rotating arc */}
      <circle
        cx={center}
        cy={center}
        r={radius}
        stroke={currentPalette.success.main}
        strokeWidth={thickness}
        fill="none"
        strokeDasharray={circumference * 0.7}
        strokeDashoffset={circumference * 0.3}
        strokeLinecap="round"
      >
        <animateTransform
          attributeName="transform"
          type="rotate"
          from={`0 ${center} ${center}`}
          to={`360 ${center} ${center}`}
          dur="600ms"
          repeatCount="indefinite"
        />
      </circle>
    </svg>
  );
};

export default Loader;
