import { useEffect, useState } from 'react';
import { useLoginMutation } from '@apiRtk/login';
import { SignInDto } from '@appTypes/models/auth.dto';
import { AppLink } from '@components/AppLink';
import { BasicForm, BasicFormField } from '@components/BasicForm';
import { Spinner } from '@components/Spinner';
import { yupResolver } from '@hookform/resolvers/yup';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { authActions } from '@redux/slices/auth';
import logger from '@services/logger';
import { AUTHENTICATION_FAILED_MESSAGE } from '@settings/config';
import { t } from '@utils/translate';
import { paths } from 'paths';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { validationSchema } from './validations';

type ErrorData = {
  detail: string | string[];
};

type Error = {
  status: number;
  data: ErrorData;
};

const getErrorMessage = (error: Nullable<Error>) => {
  if (!error?.data) {
    return t(AUTHENTICATION_FAILED_MESSAGE);
  }

  if (typeof error.data.detail === 'string') {
    if (!error.data.detail || error.data.detail.startsWith('Request failed')) {
      return t(AUTHENTICATION_FAILED_MESSAGE);
    }
    return error.data.detail || t(AUTHENTICATION_FAILED_MESSAGE);
  }
  return error.data.detail[0] || t(AUTHENTICATION_FAILED_MESSAGE);
};

const fields: BasicFormField[] = [
  { label: t('labelEmail'), name: 'username', useInputLabel: true },
  {
    label: t('labelPassword'),
    name: 'password',
    type: 'password',
    useInputLabel: true,
  },
];

const Login = () => {
  const [login, { isLoading }] = useLoginMutation();
  const dispatch = useDispatch();
  const { register, handleSubmit, watch, formState } = useForm({
    resolver: yupResolver(validationSchema),
  });

  const [isOnline, setIsOnline] = useState(navigator.onLine);

  useEffect(() => {
    const updateOnlineStatus = () => {
      setIsOnline(navigator.onLine);
    };

    window.addEventListener('online', updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    return () => {
      window.removeEventListener('online', updateOnlineStatus);
      window.removeEventListener('offline', updateOnlineStatus);
    };
  }, []);

  const onSubmit: SubmitHandler<SignInDto> = async (formValues) => {
    if (!isOnline) {
      dispatch(
        createAlert({
          message: t('errorOffline'),
          variant: 'error',
        }),
      );
      return;
    }

    try {
      const result = await login(formValues).unwrap();
      dispatch(authActions.authorise(result));
    } catch (error) {
      const apiError = error as Nullable<Error>;

      if (!apiError?.status || apiError.status === 0) {
        dispatch(
          createAlert({
            message: t('errorAppBehindFirewall'),
            variant: 'error',
          }),
        );

        logger.log(t('errorAppBehindFirewall'), {
          level: 'error',
          extra: {
            email: formValues.username,
          },
          addDebugInfo: true,
        });
      } else {
        dispatch(
          createAlert({
            message: getErrorMessage(apiError),
            variant: 'error',
          }),
        );
      }
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Typography component="h1" variant="h6" pb={2}>
        {t('headingPleaseEnterCredentials')}
      </Typography>
      {!isOnline && (
        <Typography color="error" pb={2}>
          {t('errorOffline')}
        </Typography>
      )}
      <Box
        component="form"
        onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}
        noValidate
        maxWidth={280}
        mt={1}
      >
        <BasicForm
          columns={1}
          submitBtnLabel={t('buttonLogin')}
          watch={watch}
          register={register}
          fields={fields}
          formState={formState}
        />
      </Box>
      <AppLink to={paths.forgotPassword}>{t('linkLostPassword')}</AppLink>
    </>
  );
};

export default Login;
