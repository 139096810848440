import { CenterX, FlexColumn } from '@components/LayoutUtils';
import { GridProps, Skeleton } from '@mui/material';

const SkeletonCheckboxList = (props: GridProps) => (
  <FlexColumn gap={3} {...props}>
    {[...Array(6)].map((_, index) => (
      // eslint-disable-next-line react/no-array-index-key
      <CenterX key={`list-item-${index}`} gap={1.5}>
        <Skeleton variant="rectangular" width={17} height={17} />
        <Skeleton variant="text" animation="wave" width="90%" height={20} />
      </CenterX>
    ))}
  </FlexColumn>
);

export default SkeletonCheckboxList;
