import {
  CrowdinError,
  CrowdinValidationError,
  LanguagesModel,
  ProjectsGroupsModel,
} from '@crowdin/crowdin-api-client';
import { BaseQueryFn, createApi } from '@reduxjs/toolkit/query/react';
import axios, { AxiosRequestConfig } from 'axios';

const CROWDIN_API_BASE_URL = 'https://api.crowdin.com/api/v2';
const CROWDIN_API_TOKEN = import.meta.env.VITE_APP_CROWDIN_TOKEN || '';
const PROJECT_ID = '766273';

const apiClient = axios.create({
  baseURL: CROWDIN_API_BASE_URL,
  headers: {
    Authorization: `Bearer ${CROWDIN_API_TOKEN}`,
    'Content-Type': 'application/json',
  },
});

const axiosBaseQuery: BaseQueryFn<string | AxiosRequestConfig, unknown, unknown> = async (args) => {
  const requestConfig = typeof args === 'string' ? { url: args } : args;

  try {
    const result = await apiClient(requestConfig);
    return { data: result.data };
  } catch (error) {
    if (error instanceof CrowdinValidationError) {
      return {
        error: {
          status: error.code,
          data: error.message,
        },
      };
    }
    if (error instanceof CrowdinError) {
      return {
        error: {
          status: error.code,
          data: error.message,
        },
      };
    }
    if (axios.isAxiosError(error)) {
      if (error.response) {
        return {
          error: {
            status: error.response.status,
            data: error.response.data || error.message,
          },
        };
      }
      if (error.request) {
        return {
          error: {
            status: 'NO_RESPONSE',
            data: 'The request was made but no response was received',
          },
        };
      }
      return {
        error: {
          status: 'REQUEST_SETUP_ERROR',
          data: error.message,
        },
      };
    }
    return {
      error: {
        status: 'UNKNOWN_ERROR',
        data: 'An unexpected error occurred',
      },
    };
  }
};

type ProjectLanguage = Pick<LanguagesModel.Language, 'id' | 'name' | 'twoLettersCode'>;

export const crowdinApi = createApi({
  reducerPath: 'crowdinApi',
  baseQuery: axiosBaseQuery,
  endpoints: (builder) => ({
    getProjectLanguages: builder.query<ProjectLanguage[], void>({
      query: () => `/projects/${PROJECT_ID}`,
      transformResponse: (response: { data: ProjectsGroupsModel.Project }) =>
        response.data.targetLanguages.map((lang) => ({
          id: lang.id,
          name: lang.name,
          twoLettersCode: lang.twoLettersCode,
        })),
    }),
  }),
});

export const { useGetProjectLanguagesQuery } = crowdinApi;
