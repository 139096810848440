import { ApiError } from '@appTypes/api';
import {
  ActionType,
  RequestSuccessActionType,
  SetErrorActionType,
  SetLoadingActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import {
  CreateOrganizationDto,
  OrganizationDto,
  OrganizationSimpleDto,
  UpdateOrganizationDto,
} from '@appTypes/models/organization.dto';
import { OrganizationFilters } from '@appTypes/organization/filters';

export enum OrganizationActionsType {
  SET_ORGANIZATION_REQUEST_ERROR = 'SET_ORGANIZATION_REQUEST_ERROR',
  SET_ORGANIZATION_REQUEST_LOADING = 'SET_ORGANIZATION_REQUEST_LOADING',
  REQUEST_ORGANIZATIONS = 'REQUEST_ORGANIZATIONS',
  REQUEST_ORGANIZATIONS_SUCCESS = 'REQUEST_ORGANIZATIONS_SUCCESS',
  REQUEST_ORGANIZATIONS_FAILED = 'REQUEST_ORGANIZATIONS_FAILED',
  FETCHED_ORGANIZATIONS = 'FETCHED_ORGANIZATIONS',
  FETCHED_ORGANIZATIONS_COUNT = 'FETCHED_ORGANIZATIONS_COUNT',
  FETCHED_UPDATE_ORGANIZATION = 'FETCHED_UPDATE_ORGANIZATION',
  FETCHED_GET_ORGANIZATION = 'FETCHED_GET_ORGANIZATION',
  GET_ORGANIZATION_SUCCESS = 'GET_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_SUCCESS = 'UPDATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATIONS_FILTER = 'UPDATE_ORGANIZATIONS_FILTER',
  FETCHED_CREATE_ORGANIZATION = 'FETCHED_CREATE_ORGANIZATION',
  CREATE_ORGANIZATION_SUCCESS = 'CREATE_ORGANIZATION_SUCCESS',
  UPDATE_ORGANIZATION_FILTER = 'UPDATE_ORGANIZATION_FILTER',
  CLEAR_ORGANIZATION_CURRENT = 'CLEAR_ORGANIZATION_CURRENT',
}

export type ClearOrganizationCurrent =
  ActionType<OrganizationActionsType.CLEAR_ORGANIZATION_CURRENT>;

export type FetchedOrganizationsCount =
  ActionType<OrganizationActionsType.FETCHED_ORGANIZATIONS_COUNT>;

export type SetOrganizationRequestError =
  SetErrorActionType<OrganizationActionsType.SET_ORGANIZATION_REQUEST_ERROR>;

export type SetOrganizationRequestLoading =
  SetLoadingActionType<OrganizationActionsType.SET_ORGANIZATION_REQUEST_LOADING>;

export type UpdateOrganizationsFilter = UpdateFilterActionType<
  OrganizationActionsType.UPDATE_ORGANIZATION_FILTER,
  OrganizationFilters
>;

export type RequestOrganizations = ActionType<OrganizationActionsType.REQUEST_ORGANIZATIONS>;

export type RequestOrganizationsSuccess = RequestSuccessActionType<
  OrganizationActionsType.REQUEST_ORGANIZATIONS_SUCCESS,
  OrganizationSimpleDto
>;

export type FetchedOrganizations = ActionType<
  OrganizationActionsType.FETCHED_ORGANIZATIONS,
  { page: number; pageSize: number }
>;

export type RequestOrganizationsFailed = ActionType<
  OrganizationActionsType.REQUEST_ORGANIZATIONS_FAILED,
  ApiError
>;

export type GetOrganizationSuccess = ActionType<
  OrganizationActionsType.GET_ORGANIZATION_SUCCESS,
  OrganizationDto
>;

export type FetchedGetOrganization = ActionType<
  OrganizationActionsType.FETCHED_GET_ORGANIZATION,
  number
>;

export type CreateOrganizationSuccess = ActionType<
  OrganizationActionsType.CREATE_ORGANIZATION_SUCCESS,
  OrganizationDto
>;

export type FetchedCreateOrganization = ActionType<
  OrganizationActionsType.FETCHED_CREATE_ORGANIZATION,
  CreateOrganizationDto
>;

export type FetchedUpdateOrganization = ActionType<
  OrganizationActionsType.FETCHED_UPDATE_ORGANIZATION,
  UpdateOrganizationDto
>;

export type UpdateOrganizationSuccess = ActionType<
  OrganizationActionsType.UPDATE_ORGANIZATION_SUCCESS,
  OrganizationDto
>;
