import { FilterParamsDto } from '@appTypes/models/common.dto';
import { NotificationListDto } from '@appTypes/models/notification.dto';
import { createParams } from '@utils/utils';
import { coreApi } from './apiWattstor';

const notificationsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getNotifications: builder.query<
      NotificationListDto,
      PropertyNullable<Omit<FilterParamsDto, 'order_by'>, 'is_active' | 'site_id'>
    >({
      query: ({ ...params }) => `notifications?${createParams(params)}`,
    }),
  }),
  overrideExisting: false,
});

export const { useGetNotificationsQuery } = notificationsApi;
