import { convertUtcStringToLocalString } from '@utils/utils';
import { format, isValid } from 'date-fns';

export const formatDate = (
  inputDate?: Nullable<string | Date>,
  formatString: string = 'yyyy-MM-dd HH:mm:ss',
) => {
  if (!inputDate) {
    return '';
  }

  let dateObj: Date;

  if (typeof inputDate === 'string') {
    dateObj = new Date(inputDate);
    if (!isValid(dateObj)) {
      return inputDate;
    }

    dateObj = new Date(convertUtcStringToLocalString(inputDate));
  } else if (inputDate instanceof Date) {
    dateObj = inputDate;
    if (!isValid(dateObj)) {
      return '';
    }
  } else {
    return '';
  }

  return format(dateObj, formatString);
};
