import { FeatureFlagConfig, FEATURE_FLAGS } from '@appTypes/common';

export const featureFlags: FeatureFlagConfig[] = [
  {
    name: FEATURE_FLAGS.DISABLE_COMING_SOON_OVERLAYS,
    label: 'labelFeatureFlagDisableComingSoonOverlays',
    environments: ['development'],
  },
  {
    name: FEATURE_FLAGS.ADVANCED_DATA_EXPORT,
    label: 'labelFeatureFlagAdvancedDataExport',
    environments: ['development', 'production'],
  },
  {
    name: FEATURE_FLAGS.GRAPH_EDITOR,
    label: 'labelFeatureFlagCustomGraphEditor',
    environments: ['development', 'production'],
  },
  {
    name: FEATURE_FLAGS.SITE_OVERVIEW_PANEL,
    label: 'labelSiteOverviewPanel',
    environments: ['development'],
  },
  {
    name: FEATURE_FLAGS.TRANSLATION_NAMESPACES,
    label: 'labelFeatureFlagTranslationNamespaces',
    environments: ['development'],
  },
  {
    name: FEATURE_FLAGS.NEW_TELEMETRY_SUBSYSTEM,
    label: 'labelFeatureFlagNewTelemetry',
    environments: ['development', 'production'],
    backendOnly: true,
  },
];
