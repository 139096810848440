import { List, ListItem, ListItemText } from '@mui/material';
import { parseISO, isValid, format } from 'date-fns';

type Field = { label: string; value: string | number | null | undefined };

interface FormViewModeProps {
  fields: Field[];
  disabled?: boolean;
}

const renderValue = (value: Field['value']) => {
  if (!value) return '-';

  if (typeof value === 'number') {
    return value;
  }

  const parsed = parseISO(value);

  if (isValid(parsed)) {
    return format(parsed, 'yyyy-MM-dd');
  }

  return value;
};

const FormViewMode = ({ fields, disabled }: FormViewModeProps) => (
  <List style={{ opacity: disabled ? 0.5 : 1 }}>
    {fields.map((field) => (
      <ListItem key={field.label}>
        <ListItemText primary={renderValue(field.value)} secondary={field.label} />
      </ListItem>
    ))}
  </List>
);

export default FormViewMode;
