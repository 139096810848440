import { useMemo } from 'react';
import { EnergyFlowDto } from '@appTypes/models/energyFlow.dto';
import ConditionalWrapper from '@components/ConditionalWrapper/ConditionalWrapper';
import { useDynamicWidth } from '@hooks';
import { styled } from '@mui/material';
import DeviceNode from './components/DeviceNode';
import Loader from './components/Loader';
import { wattstorSvg } from './elements';
import { calculateCount, getMainValues, getSanitisedParameter } from './helpers';
import { DiagramConfig } from './types';

const Center = styled('div')`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Wrapper = styled('div')`
  margin: 0 auto;
  max-width: 1200px;
  min-width: 400px;
  position: relative;
  overflow: hidden;
`;

const Logo = styled('div')`
  position: relative;
  background: ${({ theme }) => theme.palette.common.white};
  z-index: 10;
  padding: 0.8rem;
  border-radius: 50%;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;
`;
type DiagramProps = {
  config: DiagramConfig;
  data?: EnergyFlowDto;
  loading?: boolean;
};

export const Diagram = ({ config, loading = false, data }: DiagramProps) => {
  const { width, parentRef } = useDynamicWidth();

  const canvasSize = useMemo(() => Math.max(400, Math.min(1200, width)), [width]);
  const canvasHeightRatio = 0.4;
  const canvasHeight = canvasSize * canvasHeightRatio;
  const diagramRadius = useMemo(() => canvasSize / 2.9, [canvasSize]);
  const diagramDiameter = useMemo(() => diagramRadius * 2, [diagramRadius]);
  const logoSize = useMemo(() => Math.ceil(diagramRadius / 3.2), [diagramRadius]);
  const cardSize = useMemo(() => Math.max(canvasSize / 14, 48), [canvasSize]);
  const dataPrepared = useMemo(() => !loading && !!data, [loading, data]);
  const mainValues = useMemo(
    () => (dataPrepared ? getMainValues(data!, config) : []),
    [dataPrepared, data, config],
  );

  const renderDeviceNodes = () =>
    Object.keys(config).map((key) => {
      const deviceData = data?.[key as keyof typeof data];
      if (!deviceData) return null;

      const { parameters: paramObj } = deviceData;
      const parametersWithName = Object.entries(paramObj).map(([name, parameter]) =>
        getSanitisedParameter({ parameter, name, deviceName: key }),
      );
      if (!parametersWithName.length) return null;

      const { IconComponent, title, energyColor, reverseFlow, mainParameterName, angle } =
        config[key];
      const orderedParameters = mainParameterName
        ? [...parametersWithName].sort((a) => (a.name === mainParameterName ? -1 : 1))
        : parametersWithName;

      const mainParameter = orderedParameters[0];
      const energyNodeCount = calculateCount(mainParameter.value || 0, mainValues);

      let propsOverride: Partial<React.ComponentProps<typeof DeviceNode>> = {};
      if (key === 'ev') {
        const radians = (160 * Math.PI) / 180;
        const center = canvasSize / 2;
        const x = center + Math.cos(radians) * (diagramRadius * 0.8);
        const y = center * canvasHeightRatio * 0.91 + Math.sin(radians) * diagramRadius;
        const halfCardSize = cardSize / 2;
        const rotation = 2;
        propsOverride = {
          style: { position: 'relative', left: x - halfCardSize, top: y - halfCardSize },
          rotation,
          wireLength: diagramDiameter * 10.9 * Math.sin((rotation * Math.PI) / 180),
        };
      }

      return (
        <ConditionalWrapper Wrapper={Center} condition={key !== 'ev'} key={key}>
          <DeviceNode
            cardSize={cardSize}
            IconComponent={IconComponent}
            parameters={orderedParameters}
            title={title}
            energyColor={energyColor}
            count={energyNodeCount}
            rotation={angle}
            wireLength={diagramRadius * 0.8}
            reverse={reverseFlow ? (mainParameter.value || 0) < 0 : (mainParameter.value || 0) > 0}
            {...propsOverride}
          />
        </ConditionalWrapper>
      );
    });

  return (
    <div>
      <Wrapper ref={parentRef} style={{ width: '100%', height: canvasHeight }}>
        {dataPrepared && renderDeviceNodes()}
        <Center>
          <Logo data-testid="logo" style={{ width: logoSize, height: logoSize }}>
            {wattstorSvg}
            {loading && (
              <Loader
                data-testid="loader"
                size={logoSize - 2}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                }}
                thickness={4}
              />
            )}
          </Logo>
        </Center>
      </Wrapper>
    </div>
  );
};
