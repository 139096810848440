import { useGetOrganizationsAllForSelectQuery } from '@apiRtk/organizations';
import { useCreateUserMutation } from '@apiRtk/users';
import { ApiErrorPayload } from '@appTypes/api';
import { UserRole } from '@appTypes/models/user.dto';
import { ButtonSave } from '@components/Buttons/Buttons';
import { Flex, GapY } from '@components/LayoutUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentUserInfo } from '@hooks';
import { Box, CircularProgress, MenuItem, Select, TextField, Typography } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import { z } from 'zod';
import { userFormSchema } from './UserFormSchema';

const defaultValues = {
  email: '',
  first_name: '',
  last_name: '',
  phone: '',
  role: UserRole.USER,
  organization_id: 0,
};

type FormValues = z.infer<typeof userFormSchema>;

const CreateUserForm = () => {
  const { data: organizations, isLoading: isOrganizationsLoading } =
    useGetOrganizationsAllForSelectQuery();

  const { isSuperAdmin } = useCurrentUserInfo();

  const [createUser, { isLoading: isCreating }] = useCreateUserMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(userFormSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await createUser({
        ...data,
        organization_id: data.organization_id === 0 ? null : data.organization_id,
        is_active: true,
      }).unwrap();

      notificationService.success(t('successUserAdded'));
      reset(defaultValues);
    } catch (err) {
      const typedError = err as ApiErrorPayload;
      notificationService.error(
        typedError && typeof typedError?.data?.detail === 'string'
          ? typedError.data.detail
          : t('errorCommon'),
      );
    }
  };

  if (isOrganizationsLoading) {
    return <CircularProgress />;
  }

  const userRoles = [
    {
      id: UserRole.SUPERADMIN,
      name: UserRole.SUPERADMIN,
    },
    {
      id: UserRole.ADMIN,
      name: UserRole.ADMIN,
    },
    {
      id: UserRole.USER,
      name: UserRole.USER,
    },
  ];

  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              label={`${t('labelEmail')} *`}
              value={value}
              error={!!errors.email}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
          )}
        />
        {errors.email && (
          <Typography variant="subtitle2" color="error">
            {errors.email.message}
          </Typography>
        )}

        <GapY size={2} />

        <Controller
          name="first_name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              label={`${t('labelFirstName')} *`}
              value={value}
              error={!!errors.first_name}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
          )}
        />
        {errors.first_name && (
          <Typography variant="subtitle2" color="error">
            {errors.first_name.message}
          </Typography>
        )}

        <GapY size={2} />

        <Controller
          name="last_name"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              label={`${t('labelLastName')} *`}
              value={value}
              error={!!errors.last_name}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
          )}
        />
        {errors.last_name && (
          <Typography variant="subtitle2" color="error">
            {errors.last_name.message}
          </Typography>
        )}

        <GapY size={2} />

        <Controller
          name="phone"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <TextField
              label={`${t('labelPhone')} *`}
              value={value}
              error={!!errors.phone}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
          )}
        />
        {errors.phone && (
          <Typography variant="subtitle2" color="error">
            {errors.phone.message}
          </Typography>
        )}

        <GapY size={2} />

        <Controller
          name="role"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              label={`${t('labelUserRole')} *`}
              sx={{ width: '100%' }}
              value={value}
              error={!!errors.role}
              onChange={(event) => onChange(event.target.value as UserRole)}
            >
              {userRoles.map((item) => (
                <MenuItem
                  key={item.id}
                  value={item.id}
                  disabled={item.id === UserRole.SUPERADMIN && !isSuperAdmin}
                >
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.role && (
          <Typography variant="subtitle2" color="error">
            {errors.role.message}
          </Typography>
        )}

        <GapY size={2} />

        <Controller
          name="organization_id"
          control={control}
          rules={{ required: true }}
          render={({ field: { onChange, value } }) => (
            <Select
              label={t('labelOrganization')}
              sx={{ width: '100%' }}
              value={value}
              error={!!errors.organization_id}
              onChange={onChange}
            >
              <MenuItem key={0} value={0}>
                -
              </MenuItem>
              {(organizations || []).map((org) => (
                <MenuItem key={org.id} value={org.id}>
                  {org.name}
                </MenuItem>
              ))}
            </Select>
          )}
        />
        {errors.organization_id && (
          <Typography variant="subtitle2" color="error">
            {errors.organization_id.message}
          </Typography>
        )}

        <GapY size={2} />

        <Flex gap={1}>
          <ButtonSave size="large" type="submit" disabled={isCreating} />
        </Flex>
      </form>
    </Box>
  );
};

export default CreateUserForm;
