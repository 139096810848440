import { useState, useEffect, useRef, useMemo } from 'react';
import { Node } from '@appTypes/models/site.dto';
import { CenterX, CenterY, FlexColumn } from '@components/LayoutUtils';
import SkeletonCheckboxList from '@components/Skeletons/SkeletonCheckboxList/SkeletonCheckboxList';
import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import { Wrapper, ArrowIcon } from '@pages/Sites/Overview/DataExport/DevicesTree/Components';
import { t } from '@utils/translate';

type CheckboxTreeProps = {
  data?: Node[];
  selectedIds: string[];
  onChange: (updatedIds: string[]) => void;
  searchText?: string;
  loading?: boolean;
};

const CheckboxTree = ({
  data,
  selectedIds,
  onChange,
  searchText = '',
  loading,
}: CheckboxTreeProps) => {
  const [expandedNodes, setExpandedNodes] = useState<Record<string, boolean>>({});
  const previousSearchText = useRef(searchText);

  useEffect(() => {
    const newExpandedNodes: Record<string, boolean> = {};

    if (data?.length === 1) {
      newExpandedNodes[data[0].id] = true;
    }

    const shouldExpandBranch = (branch: Node) => {
      if (searchText) {
        return branch.children.some((child) =>
          child.name.toLowerCase().includes(searchText.toLowerCase()),
        );
      }
      return branch.children.some((child) => selectedIds.includes(child.id));
    };

    data?.forEach((branch) => {
      if (!newExpandedNodes[branch.id]) {
        newExpandedNodes[branch.id] = shouldExpandBranch(branch);
      }
    });

    setExpandedNodes(newExpandedNodes);
    previousSearchText.current = searchText;
    // Note intentionally omit selectedIds as we do not trigger this when manually selecting nodes, only when searching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchText, data]);

  const toggleExpand = (nodeId: string) => {
    setExpandedNodes((prev) => ({
      ...prev,
      [nodeId]: !prev[nodeId],
    }));
  };

  const handleBranchToggle = (branch: Node, checked: boolean) => {
    const updatedSelectedIds = new Set(selectedIds);
    branch.children.forEach((child) => {
      if (checked) updatedSelectedIds.add(child.id);
      else updatedSelectedIds.delete(child.id);
    });
    onChange(Array.from(updatedSelectedIds));
  };

  const handleLeafToggle = (leafId: string, checked: boolean) => {
    const updatedSelectedIds = new Set(selectedIds);
    if (checked) updatedSelectedIds.add(leafId);
    else updatedSelectedIds.delete(leafId);
    onChange(Array.from(updatedSelectedIds));
  };

  const isBranchChecked = (branch: Node) =>
    branch.children.every((child) => selectedIds.includes(child.id));

  const isBranchIndeterminate = (branch: Node) =>
    branch.children.some((child) => selectedIds.includes(child.id)) && !isBranchChecked(branch);

  const filteredData = useMemo(
    () =>
      data &&
      data
        .map((branch) => ({
          ...branch,
          children: branch.children.filter((child) =>
            child.name.toLowerCase().includes(searchText.toLowerCase()),
          ),
        }))
        .filter((branch) => branch.children.length > 0),
    [data, searchText],
  );

  return (
    <Wrapper>
      {searchText && !filteredData?.length ? (
        <Typography variant="body2">{t('infoNoItemsFound')}</Typography>
      ) : null}
      {loading ? <SkeletonCheckboxList pt={1} pl={4} /> : null}
      {!loading &&
        filteredData &&
        filteredData.map((branch) => (
          <Box key={branch.id}>
            <CenterX>
              <CenterY mr={1} sx={{ cursor: 'pointer' }} onClick={() => toggleExpand(branch.id)}>
                <ArrowIcon open={!!expandedNodes[branch.id]} />
              </CenterY>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={isBranchChecked(branch)}
                    indeterminate={isBranchIndeterminate(branch)}
                    onChange={(e) => handleBranchToggle(branch, e.target.checked)}
                  />
                }
                label={
                  <Typography variant="body1" fontWeight="bold">
                    {branch.name}
                  </Typography>
                }
              />
            </CenterX>

            {expandedNodes[branch.id] && (
              <FlexColumn pl={8}>
                {branch.children.map((leaf) => (
                  <FormControlLabel
                    key={leaf.id}
                    control={
                      <Checkbox
                        checked={selectedIds.includes(leaf.id)}
                        onChange={(e) => handleLeafToggle(leaf.id, e.target.checked)}
                      />
                    }
                    label={leaf.name}
                  />
                ))}
              </FlexColumn>
            )}
          </Box>
        ))}
    </Wrapper>
  );
};

export default CheckboxTree;
