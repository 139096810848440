import { HttpStatusCode } from '@appTypes/api';
import { UserRole } from '@appTypes/models/user.dto';
import { Spinner } from '@components/Spinner';
import { MILLIS_SECOND } from '@constants';
import { useOnUpdate, useCurrentRole } from '@hooks';
import { Alert } from '@mui/material';
import { EcdConfigurationProvider } from '@providers/EcdConfigurationProvider';
import { FormGroupProvider } from '@providers/FormGroupProvider';
import { createAlert } from '@redux/ducks/alerts/actionCreators';
import { getEcdsSelector } from '@redux/ducks/ecds/selectors';
import { t } from '@utils/translate';
import { useSelector, useDispatch } from 'react-redux';
import { EcdConfigurationHeader } from './EcdConfigurationHeader/EcdConfigurationHeader';
import { EcdDetails } from './EcdDetails/EcdDetails';
import { EcdDevices } from './EcdDevices/EcdDevices';
import { SubmitDrawer } from './SubmitDrawer/SubmitDrawer';
import { useEcdConfigurationForm } from './useEcdConfigurationForm';

export const EcdConfiguration = ({ viewOnlyMode = false }: { viewOnlyMode?: boolean }) => {
  const {
    error,
    loading,
    devicesWFields,
    ecdDetailsFields,
    handleSubmit,
    registerEcdDetailsInp,
    registerDeviceInp,
    ecdDetailsFormControl,
    devicesFormControl,
    sentOnlyEcdDetails,
    ecdName,
  } = useEcdConfigurationForm();

  const {
    update: { loading: isUpdating },
  } = useSelector(getEcdsSelector);

  const dispatch = useDispatch();

  const { hasRole } = useCurrentRole();

  useOnUpdate(() => {
    if (isUpdating || sentOnlyEcdDetails) return;

    dispatch(
      createAlert({
        variant: 'info',
        message: 'Changes have been sent, they will be applied in a moment!',
        timeout: MILLIS_SECOND * 6,
      }),
    );
  }, [isUpdating]);

  let content = <Spinner />;

  if (error && !loading) {
    if (error.status === HttpStatusCode.NOT_FOUND) {
      content = <Alert severity="info">{t('infoECDNotFound')}</Alert>;
    }

    content = <Alert severity="error">{t('infoCannotRetrieveEcdTryAgainLater')}</Alert>;
  }

  const fieldsLoading = !devicesWFields || !ecdDetailsFields;

  if (!loading && !fieldsLoading && !error) {
    content = (
      <EcdConfigurationProvider>
        <SubmitDrawer onSubmit={handleSubmit} />
        <FormGroupProvider>
          <EcdDetails
            registerField={registerEcdDetailsInp}
            viewOnlyMode={viewOnlyMode || !hasRole(UserRole.ADMIN)}
            fields={ecdDetailsFields || []}
            control={ecdDetailsFormControl}
          />
          <EcdDevices
            registerField={registerDeviceInp}
            viewOnlyMode={viewOnlyMode}
            fields={devicesWFields || []}
            control={devicesFormControl}
          />
        </FormGroupProvider>
      </EcdConfigurationProvider>
    );
  }

  return (
    <>
      <EcdConfigurationHeader title={ecdName} />
      {content}
    </>
  );
};

export default EcdConfiguration;
