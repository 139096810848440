import { palette } from 'styles/palette';

const unitSymbols: { [key: string]: string } = {
  percent: '%',
  watt: 'W',
  kwatth: 'kWh',
  watth: 'Wh',
  celsius: '°C',
  short: '',
  volt: 'V',
  count: '',
};

export const getUnitSymbol = (unit: string) => unitSymbols[unit] || unitSymbols.short;

const preciseRound = (n: number, decimals: number = 2): number => {
  const multiplier = 10 ** decimals;
  return Math.round((n + Number.EPSILON) * multiplier) / multiplier;
};

const SI_PREFIXES = ['', 'k', 'M', 'G', 'T', 'P'];

export const divideWithSI = (value: number, decimals: number = 1) => {
  const base = 1000;

  let quotient = Math.abs(value);
  let prefixIndex = 0;

  while (quotient >= base && prefixIndex < SI_PREFIXES.length - 1) {
    quotient /= base;
    prefixIndex += 1;
  }

  const resultValue = preciseRound(quotient * Math.sign(value), decimals);
  const prefix = SI_PREFIXES[prefixIndex];

  return {
    resultValue,
    prefix,
  };
};

export const parseBigNumber = (n: number, symbol: string, decimals?: number): [number, string] => {
  if (['%', '°C'].includes(symbol)) {
    return [preciseRound(n, decimals), symbol];
  }

  if (n === 0) {
    return [n, symbol];
  }

  const prefixIndex = Math.floor(Math.log10(Math.abs(n)) / 3);
  const prefix = SI_PREFIXES[Math.max(0, prefixIndex)];

  return [preciseRound(n / 10 ** (prefixIndex * 3), decimals), `${prefix}${symbol}`];
};

export const formatNumberWithUnit = (n: number, unit: string): string => {
  if (['percent', 'celsius', 'short', 'count'].includes(unit)) {
    return `${preciseRound(n)} ${getUnitSymbol(unit)}`.trimEnd();
  }

  let newNumber = n;

  const isKwh = unit === 'kwatth';
  let unitSymbol = getUnitSymbol(unit);

  if (isKwh) {
    // Note: has already kilos SI prefix so it is 1000 times greater!
    newNumber = n * 1000;
    unitSymbol = getUnitSymbol(unit).substring(1);
  }

  if (n === 0) {
    return `0 ${unitSymbol}`;
  }

  if (Math.abs(n) < 1000) {
    return `${preciseRound(n)} ${getUnitSymbol(unit)}`;
  }

  const [value, unitWithPrefix] = parseBigNumber(newNumber, unitSymbol);

  return `${value} ${unitWithPrefix}`;
};

export const getChartColors = () => Object.values(palette.graph);

export const isApple = () => {
  const expression = /(Mac|iPhone|iPod|iPad)/i;
  return expression.test(navigator.platform);
};

export const isControl = (event: any) => {
  if (isApple()) {
    return event.metaKey;
  }
  return event.ctrlKey;
};
