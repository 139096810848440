import { ApiError } from '@appTypes/api';
import { put, call } from 'redux-saga/effects';
import { getCurrentPage } from './getCurrentPage';
import { CreateParams, SelectorReturn } from './types';

export function* create<TItem, TItemCreate, TSelector extends SelectorReturn>({
  payload,
  apiCall,
  onSuccess,
  getListActionName,
  clearCurrentActionName,
  selector,
  setRequestLoading,
  setRequestError,
}: CreateParams<TItem, TItemCreate, TSelector>) {
  try {
    yield put(setRequestLoading('create'));
    const data: TItem = yield call(apiCall, payload);
    yield put(onSuccess(data));

    yield call(getCurrentPage, {
      selector,
      getListActionName,
      clearCurrentActionName,
    });
  } catch (error) {
    yield put(setRequestError({ field: 'create', error: error as ApiError }));
    throw error;
  }
}
