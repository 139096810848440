import { ConfiguredItem } from '@components/ConfigurationList/types';
import { Tabs } from '@components/Tabs/Tabs';
import { Box, Tab } from '@mui/material';
import { t } from '@utils/translate';

const tabsLabels: { [key in ConfiguredItem]: string } = {
  [ConfiguredItem.SITES]: t('tabSites'),
  [ConfiguredItem.USERS]: t('tabUsers'),
  [ConfiguredItem.ORGANIZATIONS]: t('tabOrganizations'),
  [ConfiguredItem.ECDS]: t('tabEcds'),
};

export const RelationsTabs = ({
  value,
  tabs,
  onValueChange,
}: {
  value: ConfiguredItem;
  tabs: ConfiguredItem[];
  onValueChange: (value: ConfiguredItem) => void;
}) => (
  <Box width="100%">
    <Tabs<ConfiguredItem> value={value} onChange={(_, v) => onValueChange(v)}>
      {tabs.map((tab) => (
        <Tab
          key={tab}
          value={tab}
          label={tabsLabels[tab]}
          data-testid={`relation-tab-${tab.toLocaleLowerCase()}`}
        />
      ))}
    </Tabs>
  </Box>
);
