import { useEffect, useRef, useState } from 'react';
import {
  useGetAnalyticsQuery,
  useUpdateOrderMutation,
  useDeleteGraphMutation,
} from '@apiRtk/analytics';
import { HttpStatusCode } from '@appTypes/api';
import { DnDItem, FEATURE_FLAGS } from '@appTypes/common';
import { GraphType } from '@appTypes/models/site.dto';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { ButtonPrimary, ButtonPrimaryOutlined } from '@components/Buttons/Buttons';
import DateRangePanel from '@components/Charts/DateRangePanel/DateRangePanel';
import { DateTimeRangeProvider } from '@components/Charts/DateRangePanel/DateTimeRangeContext';
import EchartWrapper from '@components/Charts/EchartWrapper';
import { CenterX, Flex, FlexColumn } from '@components/LayoutUtils';
import ConfirmModal from '@components/Modals/ConfirmModal';
import { SiteHeaderWrapper } from '@components/SiteHeaderWrapper/SiteHeaderWrapper';
import { Spinner } from '@components/Spinner';
import AutoFixHighIcon from '@mui/icons-material/AutoFixHigh';
import CheckIcon from '@mui/icons-material/Check';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import ReorderIcon from '@mui/icons-material/Expand';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Typography,
  IconButton,
  Popover,
} from '@mui/material';
import { selectAuthSlice } from '@redux/slices/auth';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { notificationService } from '@services/notificationService/notificationService';
import { prepareError } from '@utils/data/prepareError';
import { t } from '@utils/translate';
import ReactECharts from 'echarts-for-react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { GraphEditor } from './GraphEditor/GraphEditor';
import { ReorderGraphs } from './ReorderGraphs';

const DataAnalysis = () => {
  const { currentUser } = useSelector(selectAuthSlice);
  const params = useParams();
  const siteId = parseInt(params.id!, 10);
  const refs = useRef<Array<Nullable<ReactECharts>>>([]);
  const { refetch, data: graphs, isLoading, error, isError } = useGetAnalyticsQuery(siteId);
  const [updateOrder, { isLoading: updatingOrder }] = useUpdateOrderMutation();
  const [deleteGraph] = useDeleteGraphMutation();
  const [dndItems, setDndItems] = useState<DnDItem[]>([]);
  const [initialDndItems, setInitialDndItems] = useState<DnDItem[]>([]);
  const [isReordering, setIsReordering] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [selectedGraphId, setSelectedGraphId] = useState<Nullable<string>>(null);
  const [editorOpen, setEditorOpen] = useState(false);

  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const popoverOpen = Boolean(anchorEl);
  const popoverId = popoverOpen ? 'graph-menu-popover' : undefined;

  const selectedGraphTitle = graphs?.find((g) => g.graphId === selectedGraphId)?.title;

  useEffect(() => {
    if (graphs?.length) {
      const newDndItems = graphs.map(({ graphId, title }) => ({
        id: graphId,
        title,
      }));
      setDndItems(newDndItems);
      setInitialDndItems(newDndItems); // Store the original order
    }
  }, [graphs]);

  if (isError) {
    const errorMessage =
      prepareError(error)?.status === HttpStatusCode.NOT_FOUND
        ? t('errorFetchAnalysisData')
        : t('errorCommon');
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <AlertWithRefetch onRetryClick={refetch}>{errorMessage}</AlertWithRefetch>
      </SiteHeaderWrapper>
    );
  }

  if (!graphs || isLoading || !dndItems.length) {
    return (
      <SiteHeaderWrapper siteId={siteId} siteName="...">
        <Spinner />
      </SiteHeaderWrapper>
    );
  }

  const sortedGraphs = [...graphs].sort((a, b) => {
    const indexA = dndItems.findIndex((item) => item.id === a.graphId);
    const indexB = dndItems.findIndex((item) => item.id === b.graphId);
    return indexA - indexB;
  });

  const handleItemsReordered = (newItems: DnDItem[]) => {
    setDndItems(newItems);
  };

  const handleReorderClick = () => {
    setIsReordering(true);
  };

  const handleCancelReorder = () => {
    setDndItems(initialDndItems);
    setIsReordering(false);
  };

  const handleSaveOrder = async () => {
    try {
      await updateOrder({
        siteId,
        items: sortedGraphs,
      }).unwrap();
      setIsReordering(false);
    } catch {
      notificationService.error(t('errorApplyGraphReorder'));
    }
  };

  const handleDeleteGraphConfirmed = async (graphId: string) => {
    try {
      await deleteGraph({ siteId, graphName: graphId }).unwrap();
    } catch {
      notificationService.error(t('errorDeleteGraph'));
    }
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLElement>, graphId: string) => {
    e.stopPropagation();
    setAnchorEl(e.currentTarget);
    setSelectedGraphId(graphId);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteOptionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
    setConfirmModalOpen(true);
  };

  const handleEditOptionClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    setAnchorEl(null);
    setEditorOpen(true);
  };

  const currentSite = currentUser?.sites.find(({ id }) => id === siteId);
  const pageTitle = currentSite ? currentSite.name : '-';

  return (
    <SiteHeaderWrapper siteId={siteId} siteName={pageTitle}>
      <DateTimeRangeProvider>
        {isReordering ? (
          <>
            <Flex justifyContent="flex-end" gap={1}>
              <ButtonPrimary
                disabled={updatingOrder}
                size="small"
                startIcon={updatingOrder ? <Spinner size={18} color="white" /> : <CheckIcon />}
                onClick={handleSaveOrder}
              >
                {t('buttonApplyOrder')}
              </ButtonPrimary>
              <ButtonPrimaryOutlined size="small" sx={{ mr: 1 }} onClick={handleCancelReorder}>
                {t('buttonCancel')}
              </ButtonPrimaryOutlined>
            </Flex>
            <ReorderGraphs items={dndItems} onReorder={handleItemsReordered} />
          </>
        ) : (
          <>
            <Flex justifyContent="flex-end" gap={1}>
              <ButtonPrimaryOutlined
                size="small"
                startIcon={<ReorderIcon />}
                onClick={handleReorderClick}
              >
                {t('buttonReorderGraphs')}
              </ButtonPrimaryOutlined>
              {isFeatureFlagEnabled(FEATURE_FLAGS.GRAPH_EDITOR) ? (
                <>
                  <ButtonPrimary
                    startIcon={<AutoFixHighIcon />}
                    size="small"
                    onClick={() => {
                      setEditorOpen(true);
                      setSelectedGraphId(null);
                    }}
                  >
                    {t('buttonCreateCustomGraph')}
                  </ButtonPrimary>
                  <GraphEditor
                    open={editorOpen}
                    setOpen={setEditorOpen}
                    graphId={selectedGraphId}
                  />
                </>
              ) : null}
            </Flex>
            <DateRangePanel />
            <ConfirmModal
              open={confirmModalOpen}
              setOpen={setConfirmModalOpen}
              onConfirm={() => handleDeleteGraphConfirmed(selectedGraphId!)}
              dialogTitle={t('dialogTitleConfirmDeleteGraph')}
            >
              <Typography variant="body1">
                {t('dialogTextConfirmDeleteGraph', {
                  graphId: selectedGraphTitle,
                })}
              </Typography>
            </ConfirmModal>
            {sortedGraphs.map(({ graphId, title, actions, graphType }, index) => (
              <Accordion
                key={graphId}
                style={{ marginTop: 10, backgroundColor: 'white' }}
                defaultExpanded
                elevation={0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`panel${index}-content`}
                  id={`panel${index}-header`}
                >
                  <CenterX justifyContent="space-between" width="100%">
                    <Typography variant="body1">{title}</Typography>
                    {graphType === GraphType.CUSTOM ? (
                      <IconButton size="small" onClick={(e) => handleMenuClick(e, graphId)}>
                        <MoreVertRoundedIcon color="primary" />
                      </IconButton>
                    ) : null}
                  </CenterX>
                </AccordionSummary>
                <AccordionDetails>
                  <Box marginTop="auto" marginBottom="auto">
                    <EchartWrapper
                      refs={refs}
                      groupRef={(el) => {
                        refs.current[index] = el;
                      }}
                      height={250}
                      deviceId={graphId}
                      dataLink={actions.get}
                      index={index}
                    />
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))}
            <Popover
              id={popoverId}
              open={popoverOpen}
              anchorEl={anchorEl}
              onClose={handlePopoverClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <FlexColumn gap={1} p={1}>
                <CenterX gap={1} onClick={handleEditOptionClick} px={2} sx={{ cursor: 'pointer' }}>
                  <EditIcon color="primary" />
                  <Typography variant="body1">{t('buttonEdit')}</Typography>
                </CenterX>
                <CenterX
                  gap={1}
                  onClick={handleDeleteOptionClick}
                  px={2}
                  sx={{ cursor: 'pointer' }}
                >
                  <DeleteIcon color="error" />
                  <Typography color="error" variant="body1">
                    {t('buttonDelete')}
                  </Typography>
                </CenterX>
              </FlexColumn>
            </Popover>
          </>
        )}
      </DateTimeRangeProvider>
    </SiteHeaderWrapper>
  );
};

export default DataAnalysis;
