import { ApiError } from '@appTypes/api';
import { DefaultItem } from '@appTypes/helpers/redux';
import { put, call } from 'redux-saga/effects';
import { getCurrentPage } from './getCurrentPage';
import { DeleteParams, SelectorReturn } from './types';

export function* deleteItem<TItem extends DefaultItem, TSelector extends SelectorReturn>({
  payload,
  setRequestError,
  setRequestLoading,
  onSuccess,
  apiCall,
  selector,
  getListActionName,
  clearCurrentActionName,
}: DeleteParams<TItem, TSelector>) {
  try {
    yield put(setRequestLoading('delete'));
    yield call(apiCall, payload);
    yield put(onSuccess(payload));

    yield call(getCurrentPage, {
      selector,
      getListActionName,
      clearCurrentActionName,
    });
  } catch (error) {
    yield put(setRequestError({ field: 'delete', error: error as ApiError }));
    throw error;
  }
}
