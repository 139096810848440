import { useState } from 'react';
import { FEATURE_FLAGS } from '@appTypes/common';
import { Can } from '@components/Can';
import LanguageSwitcher from '@components/LanguageSwitcher';
import { CenterX, Flex } from '@components/LayoutUtils';
import RoleIcon from '@components/RoleIcon/RoleIcon';
import { useRedirect, useCurrentUserInfo } from '@hooks';
import logo from '@images/logotype.png';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import MoreVertRoundedIcon from '@mui/icons-material/MoreVertRounded';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Box,
  Drawer,
  Popover,
  IconButton,
} from '@mui/material';
import { authActions } from '@redux/slices/auth';
import { selectProfileSwitcherSlice } from '@redux/slices/profileSwitch';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { MAIN_MENU_WIDTH } from '@settings/constants';
import { t } from '@utils/translate';
import { navItems } from 'navItems';
import { paths } from 'paths';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { palette } from 'styles/palette';
import { ProfileSwitcher } from './ProfileSwitcher/ProfileSwitcher';

export const Navbar = () => {
  const { isWattstorUser, currentUser } = useCurrentUserInfo();
  const [expanded, setExpanded] = useState<string | false>(false);
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLButtonElement>>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    setExpanded(newExpanded ? panel : false);
  };

  const redirect = useRedirect();
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const { sessionActive } = useSelector(selectProfileSwitcherSlice);

  const open = Boolean(anchorEl);
  const popoverId = open ? 'popover-menu' : undefined;

  return (
    <Drawer
      open
      PaperProps={{
        sx: { width: MAIN_MENU_WIDTH, p: 1.5, boxSizing: 'border-box' },
      }}
      variant="persistent"
      className="appScrollBar"
    >
      <Box px="12px" pb="24px">
        <Link to={paths.sites} title={t('linkBackToHomepage')}>
          <img src={logo} width="134" height="24" alt={t('altLogo')} />
        </Link>
      </Box>

      {!sessionActive ? (
        <CenterX justifyContent="space-between" py="6px" px="16px">
          <CenterX gap="32px">
            <RoleIcon role={currentUser?.role.name || undefined} />
            <Typography variant="body1">
              {currentUser?.last_name}, {currentUser?.first_name}
            </Typography>
          </CenterX>
          <div>
            <IconButton
              data-cy="user-menu-button"
              aria-describedby={popoverId}
              onClick={handleClick}
            >
              <MoreVertRoundedIcon color="primary" />
            </IconButton>
            <Popover
              id={popoverId}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <Box
                onClick={() => dispatch(authActions.logout())}
                display="flex"
                alignItems="center"
                sx={{
                  cursor: 'pointer',
                  padding: '14px 16px',
                }}
              >
                <LogoutRoundedIcon color="primary" sx={{ marginRight: '12px' }} />
                <Typography color={palette.primary.main} variant="body1" sx={{ fontSize: '16px' }}>
                  {t('buttonLogout')}
                </Typography>
              </Box>
            </Popover>
          </div>
        </CenterX>
      ) : null}

      <Box pt="21px">
        <ProfileSwitcher />
      </Box>

      {navItems.map(
        ({
          title,
          items,
          id,
          IconComponent,
          permissions,
          alwaysExpanded,
          path,
          wattstorUserOnly,
          CustomItemComponent,
        }) => {
          const isCurrentPath = pathname === path;

          const subItems = items?.map(
            ({ path: subItemPath, title: subItemTitle, id: subItemId }) => (
              <AccordionDetails key={subItemId}>
                <Typography fontWeight={900}>
                  <Link
                    to={subItemPath}
                    style={{
                      color: palette.primary.main,
                      textDecoration: 'none',
                    }}
                  >
                    {subItemTitle}
                  </Link>
                </Typography>
              </AccordionDetails>
            ),
          );

          const redirectOnClick = path ? () => redirect(() => path) : undefined;

          const { isExpanded, onChange, onClick } = (() => {
            if (alwaysExpanded) {
              return {
                isExpanded: true,
                onClick: redirectOnClick,
                onChange: undefined,
              };
            }

            if (CustomItemComponent || subItems) {
              return {
                isExpanded: expanded === id,
                onChange: handleChange(id),
                onClick: undefined,
              };
            }

            return {
              isExpanded: false,
              onClick: redirectOnClick,
              onChange: undefined,
            };
          })();

          if (wattstorUserOnly && !isWattstorUser) {
            return null;
          }

          return (
            <Can key={id} permissions={permissions || []}>
              <Accordion
                expanded={isExpanded}
                onChange={onChange}
                sx={{
                  boxShadow: 'none',
                }}
              >
                <AccordionSummary
                  onClick={onClick}
                  sx={{
                    margin: 0,
                    padding: 0,
                    '& .MuiAccordionSummary-content': {
                      margin: 0,
                      padding: 0,
                    },
                    '& .MuiAccordionSummary-content.Mui-expanded': {
                      margin: 0,
                    },
                  }}
                >
                  <Flex
                    gap="32px"
                    flex={1}
                    height="48px"
                    alignItems="center"
                    p="0 16px"
                    bgcolor={isCurrentPath ? palette.neutral.light : 'transparent'}
                    borderRadius="4px"
                  >
                    {IconComponent && <IconComponent color="primary" />}
                    <Typography color="primary">{title}</Typography>
                  </Flex>
                </AccordionSummary>
                {CustomItemComponent ? <CustomItemComponent /> : subItems}
              </Accordion>
            </Can>
          );
        },
      )}
      <Box mt="auto">
        {isFeatureFlagEnabled(FEATURE_FLAGS.TRANSLATION_NAMESPACES) ? <LanguageSwitcher /> : null}
      </Box>
    </Drawer>
  );
};
