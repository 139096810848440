import React, { useState } from 'react';
import { useGetProjectLanguagesQuery } from '@apiRtk/apiCrowdin';
import { useSetUserLanguageMutation } from '@apiRtk/users';
import { useCurrentUserInfo } from '@hooks/useCurrentUserInfo';
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import LanguageIcon from '@mui/icons-material/Language';
import { Popover, CircularProgress, Chip, Typography } from '@mui/material';
import CONFIG from '@settings/config';
import { ButtonText } from './Buttons/Buttons';
import { CenterX, FlexColumn } from './LayoutUtils';

function LanguageSwitcher() {
  const [anchorEl, setAnchorEl] = useState<Nullable<HTMLElement>>(null);
  const { data: languages, isLoading } = useGetProjectLanguagesQuery();
  const { currentUser } = useCurrentUserInfo();
  const userId = currentUser?.id;
  const userLanguage = currentUser?.language;
  const [currentLanguage, setCurrentLanguage] = useState(userLanguage || CONFIG.DEFAULT_LANGUAGE);
  const [setUserLanguage] = useSetUserLanguageMutation();

  if (!userId) return null;

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLanguageSwitch = (twoLettersCode: string) => {
    setCurrentLanguage(twoLettersCode);
    setUserLanguage({ userId, language: twoLettersCode });
    handleClose();
  };

  const currentLanguageObj = languages?.find((lang) => lang.twoLettersCode === currentLanguage);
  const currentLanguageName = currentLanguageObj
    ? currentLanguageObj.name
    : currentLanguage.toUpperCase();

  if (!languages?.length) {
    return (
      <Chip
        icon={<LanguageIcon color="primary" />}
        label={<Typography variant="button">English</Typography>}
      />
    );
  }

  return (
    <>
      <Chip
        icon={<LanguageIcon color="primary" />}
        label={
          <CenterX sx={{ textTransform: 'uppercase' }} mr={-1} gap={1}>
            <Typography variant="button">{currentLanguageName}</Typography>
            <ExpandCircleDownIcon color="disabled" />
          </CenterX>
        }
        onClick={handleOpen}
        clickable
      />

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        transformOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <FlexColumn p={1}>
          {isLoading && <CircularProgress size={24} />}
          {languages?.map(({ twoLettersCode, name }) => (
            <ButtonText
              key={twoLettersCode}
              onClick={() => handleLanguageSwitch(twoLettersCode || CONFIG.DEFAULT_LANGUAGE)}
              color="primary"
            >
              {name}
            </ButtonText>
          ))}
        </FlexColumn>
      </Popover>
    </>
  );
}

export default LanguageSwitcher;
