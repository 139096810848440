import { useState } from 'react';
import { useGetCustomGraphPreviewMutation } from '@apiRtk/graphs';
import { GraphDto } from '@appTypes/models/site.dto';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { APIErrorResponse, getErrorDetail } from '@utils/utils';

export const useGraphPreview = (
  siteId: number,
  name: string,
  metrics: string[],
  onSuccess: () => void,
) => {
  const [previewData, setPreviewData] = useState<GraphDto | undefined>();
  const [hasRequestedPreview, setHasRequestedPreview] = useState(false);
  const [createPreview, { isLoading, isError }] = useGetCustomGraphPreviewMutation();

  const fetchPreview = async () => {
    setHasRequestedPreview(true);

    try {
      const result = await createPreview({ siteId, name, items: metrics });

      if ('data' in result) {
        setPreviewData(result.data);
        onSuccess();
      } else {
        const errorDetail = getErrorDetail(result.error as Partial<APIErrorResponse>);
        notificationService.error(`${t('errorCreatingPreview')}: ${errorDetail}`);
      }
    } catch (error) {
      notificationService.error(t('errorCreatingPreview'));
    }
  };

  return {
    previewData,
    fetchPreview,
    isLoading,
    isError,
    hasRequestedPreview,
  };
};
