import { ApiError } from '@appTypes/api';
import {
  ActionType,
  RequestSuccessActionType,
  SetErrorActionType,
  SetLoadingActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import { SiteDto, SiteSimpleDto } from '@appTypes/models/site.dto';
import { SiteFilters } from '@appTypes/sites/filters';

export enum SitesActionsTypes {
  SET_SITE_REQUEST_ERROR = 'SET_SITE_REQUEST_ERROR',
  SET_SITE_REQUEST_LOADING = 'SET_SITE_REQUEST_LOADING',
  CLEAR_SITE_CURRENT = 'CLEAR_SITE_CURRENT',
  GET_SITE_SUCCESS = 'GET_SITE_SUCCESS',
  REQUEST_SITES = 'REQUEST_SITES',
  REQUEST_SITES_SUCCESS = 'REQUEST_SITES_SUCCESS',
  REQUEST_SITES_FAILED = 'REQUEST_SITES_FAILED',
  FETCHED_SITES = 'FETCHED_SITES',
  FETCHED_SITES_COUNT = 'FETCHED_SITES_COUNT',
  UPDATE_SITES_FILTER = 'UPDATE_SITES_FILTER',
  FETCHED_GET_SITE = 'FETCHED_GET_SITE',
  RESET_SITES = 'RESET_SITES',
}

export type FetchedSitesCount = ActionType<SitesActionsTypes.FETCHED_SITES_COUNT>;

export type ResetSites = ActionType<SitesActionsTypes.RESET_SITES>;

export type ClearSiteCurrent = ActionType<SitesActionsTypes.CLEAR_SITE_CURRENT>;

export type SetSiteRequestError = SetErrorActionType<SitesActionsTypes.SET_SITE_REQUEST_ERROR>;

export type SetSiteRequestLoading =
  SetLoadingActionType<SitesActionsTypes.SET_SITE_REQUEST_LOADING>;

export type UpdateSitesFilter = UpdateFilterActionType<
  SitesActionsTypes.UPDATE_SITES_FILTER,
  SiteFilters
>;

export type RequestSites = ActionType<SitesActionsTypes.REQUEST_SITES>;

export type RequestSitesSuccess = RequestSuccessActionType<
  SitesActionsTypes.REQUEST_SITES_SUCCESS,
  SiteSimpleDto
>;

export type FetchedSites = ActionType<
  SitesActionsTypes.FETCHED_SITES,
  { page: number; pageSize: number }
>;

export type RequestSitesFailed = ActionType<SitesActionsTypes.REQUEST_SITES_FAILED, ApiError>;

export type FetchedGetSite = ActionType<SitesActionsTypes.FETCHED_GET_SITE, SiteDto['id']>;

export type GetSiteSuccess = ActionType<SitesActionsTypes.GET_SITE_SUCCESS, SiteDto>;
