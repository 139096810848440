import { ApiError } from '@appTypes/api';
import { DefaultItem } from '@appTypes/helpers/redux';
import { put, call } from 'redux-saga/effects';
import { GetSingleParams } from './types';

export function* getSingle<TItem extends DefaultItem>({
  payload,
  onSuccess,
  apiCall,
  setRequestLoading,
  setRequestError,
}: GetSingleParams<TItem>) {
  try {
    yield put(setRequestLoading('current'));
    const data: TItem = yield call(apiCall, payload);
    yield put(onSuccess(data));
  } catch (error) {
    yield put(setRequestError({ field: 'current', error: error as ApiError }));
    throw error;
  }
}
