import { Helmet } from 'react-helmet-async';

const MatomoTracker = () => (
  <Helmet>
    <script>
      {`
        var _paq = window._paq = window._paq || [];
        /* tracker methods like "setCustomDimension" should be called before "trackPageView" */
        _paq.push(['trackPageView']);
        _paq.push(['enableLinkTracking']);
        (function() {
          var u="https://wattstor.matomo.cloud/";
          _paq.push(['setTrackerUrl', u+'matomo.php']);
          _paq.push(['setSiteId', '4']);
          var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
          g.async=true; g.src='https://cdn.matomo.cloud/wattstor.matomo.cloud/matomo.js'; s.parentNode.insertBefore(g,s);
        })();
        `}
    </script>
  </Helmet>
);

export default MatomoTracker;
