import { divideWithSI } from '@components/Charts/helpers';
import { Flex } from '@components/LayoutUtils';
import { Typography } from '@mui/material';
import { SiteOverviewRowItem } from './SiteOverviewRowItem';

type SiteOverviewRowProps = {
  item: SiteOverviewRowItem;
};

const SiteOverviewRow = ({ item }: SiteOverviewRowProps) => {
  const decimals = 1;
  let displayValue = '';
  let prefix = '';

  if (item.value === null || item.value === undefined) {
    displayValue = 'N/A';
  } else {
    const numericValue = typeof item.value === 'number' ? item.value : parseFloat(item.value);

    if (!Number.isNaN(numericValue) && item.unit) {
      const result = divideWithSI(numericValue, decimals);
      prefix = result.prefix;
      displayValue = result.resultValue.toFixed(decimals);
    } else {
      displayValue = item.value.toString();
    }
  }

  return (
    <Flex gap="32px" flex={1} paddingBottom={1} justifyContent="space-between" alignItems="top">
      <Typography sx={{ color: 'white' }}>{item.key}</Typography>
      <Flex sx={{ textAlign: 'right', alignItems: 'baseline' }}>
        <Typography color="white" sx={{ fontWeight: 'bold' }}>
          {displayValue}
        </Typography>
        {displayValue !== 'N/A' && item.unit && (
          <Typography color="white" pl={1}>
            {prefix && ` ${prefix}`}
            {item.unit}
          </Typography>
        )}
      </Flex>
    </Flex>
  );
};

export default SiteOverviewRow;
