import { forwardRef, useImperativeHandle, useRef } from 'react';
import { FormValuesNewCustomGraph } from '@appTypes/forms';
import ECharts from '@components/Charts/Echarts';
import { CenterXY, FlexColumn } from '@components/LayoutUtils';
import { GraphLoader } from '@components/Spinner';
import { Alert, Box, Paper, Typography } from '@mui/material';
import { t } from '@utils/translate';
import ReactECharts, { EChartsReactProps } from 'echarts-for-react';
import { UseFormTrigger } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { useGraphPreview } from './useGraphPreview';

type GraphPreviewProps = Omit<EChartsReactProps, 'option'> & {
  name: string;
  metrics: string[];
  trigger: UseFormTrigger<FormValuesNewCustomGraph>;
  onPreviewSuccessful: () => void;
};

const previewGraphHeight = 300;

const GraphPreview = forwardRef(
  ({ name, metrics, trigger, onPreviewSuccessful, ...props }: GraphPreviewProps, ref) => {
    const params = useParams();
    const siteId = parseInt(params.id!, 10);

    const refs = useRef<Array<ReactECharts | null>>([]);

    const { previewData, fetchPreview, isLoading, isError, hasRequestedPreview } = useGraphPreview(
      siteId,
      name,
      metrics,
      onPreviewSuccessful,
    );

    const handlePreviewButtonClick = async () => {
      const isFormValid = await trigger();
      if (isFormValid) {
        fetchPreview();
      }
    };

    useImperativeHandle(ref, () => ({
      handlePreviewButtonClick,
    }));

    let content;

    if (hasRequestedPreview && isLoading) {
      content = <GraphLoader />;
    } else if (isError) {
      content = <Alert severity="error">{t('errorGraphCommon')}</Alert>;
    } else if (previewData) {
      content = (
        <>
          <Typography variant="body1" pb={2}>
            {name}
          </Typography>
          <ECharts
            height={previewGraphHeight}
            refs={refs}
            deviceId="preview-graph"
            graphData={previewData}
            {...props}
          />
        </>
      );
    } else {
      content = (
        <CenterXY height={previewGraphHeight}>
          <Typography variant="body2">{t('infoSelectMetrics')}</Typography>
        </CenterXY>
      );
    }

    return (
      <FlexColumn>
        <Typography variant="h6" mb={2}>
          {t('buttonGraphPreview')}
        </Typography>

        <Paper>
          <Box
            sx={{
              minHeight: previewGraphHeight,
              display: 'flex',
              flexDirection: 'column',
              position: 'relative',
              padding: 2,
            }}
          >
            {content}
          </Box>
        </Paper>
      </FlexColumn>
    );
  },
);

export default GraphPreview;
