import { useEffect, useRef, useState } from 'react';
import { useGetAnalyticsQuery } from '@apiRtk/analytics';
import { useCreateCustomGraphMutation, useUpdateCustomGraphMutation } from '@apiRtk/graphs';
import { useGetDeviceMetricsQuery } from '@apiRtk/sites';
import { FormValuesNewCustomGraph } from '@appTypes/forms';
import AlertWithRefetch from '@components/AlertWithRefetch/AlertWithRefetch';
import { ButtonPrimary } from '@components/Buttons/Buttons';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { Flex, FlexColumn } from '@components/LayoutUtils';
import FormModal from '@components/Modals/FormModal';
import { zodResolver } from '@hookform/resolvers/zod';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { TextField, Typography } from '@mui/material';
import { DevicesTree } from '@pages/Sites/Overview/DataExport/DevicesTree/DeviceTree';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { getErrorDetail, APIErrorResponse } from '@utils/utils';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useParams } from 'react-router';
import { palette } from 'styles/palette';
import { z } from 'zod';
import GraphPreview from './GraphPreview';

const customGraphSchema = z.object({
  name: z
    .string({
      required_error: t('validationNameRequired'),
    })
    .min(1, t('validationNameRequired'))
    .max(50, t('validationCustomGraphNameMaxLength')),
  metrics: z.array(z.string()).nonempty({ message: t('validationMetricsMin') }),
});

const defaultValues: FormValuesNewCustomGraph = {
  name: 'New custom graph',
  metrics: [],
};

interface GraphEditorProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  graphId?: Nullable<string>;
}

export const GraphEditor = ({ open, setOpen, graphId }: GraphEditorProps) => {
  const params = useParams();
  const siteId = parseInt(params.id!, 10);
  const [previewCreated, setPreviewCreated] = useState(false);
  const { data: graphsData, isLoading: graphLoading } = useGetAnalyticsQuery(siteId);
  const graph = graphsData?.find((g) => g.graphId === graphId);
  const isEditMode = !!graphId;

  const [createCustomGraph, { isLoading: createGraphLoading }] = useCreateCustomGraphMutation();
  const [updateCustomGraph, { isLoading: updateGraphLoading }] = useUpdateCustomGraphMutation();

  const graphPreviewRef = useRef<{ handlePreviewButtonClick: () => Promise<void> }>(null);

  const handlePreviewButtonClick = () => {
    graphPreviewRef.current?.handlePreviewButtonClick();
  };

  const {
    refetch,
    isLoading: deviceMetricsLoading,
    data: deviceMetrics,
    isError: deviceMetricsError,
  } = useGetDeviceMetricsQuery({ siteId, all: true }, { skip: !siteId || !open });

  const {
    handleSubmit,
    trigger,
    control,
    formState: { errors },
    reset,
    watch,
  } = useForm<FormValuesNewCustomGraph>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(customGraphSchema),
  });

  const name = watch('name');
  const metrics = watch('metrics');

  const errorMessagePrefix = isEditMode ? 'errorUpdatingCustomGraph' : 'errorCreatingCustomGraph';

  // Reinitialize form values whenever the modal opens
  useEffect(() => {
    if (open) {
      if (graphId && graph) {
        reset({
          name: graph.title || 'New custom graph',
          metrics: graph.seriesIds || [],
        });
      } else {
        reset(defaultValues);
      }
      setPreviewCreated(false);
    }
  }, [open, graphId, graph, reset]);

  useEffect(() => {
    setPreviewCreated(false);
  }, [metrics]);

  const handleFormSubmit: SubmitHandler<FormValuesNewCustomGraph> = async (fieldValues) => {
    const isFormValid = await trigger();
    if (isFormValid) {
      try {
        let result: any;
        if (isEditMode) {
          const editLink = graph?.actions.edit;
          if (editLink) {
            result = await updateCustomGraph({
              editLink,
              items: fieldValues.metrics,
              name: fieldValues.name,
            });
          } else {
            throw new Error('Edit link not defined');
          }
        } else {
          result = await createCustomGraph({
            siteId,
            items: fieldValues.metrics,
            name: fieldValues.name,
          });
        }

        if ('data' in result) {
          setOpen(false);
          reset();
        } else {
          const errorDetail = getErrorDetail(result.error as Partial<APIErrorResponse>);
          notificationService.error(`${t(errorMessagePrefix)}: ${errorDetail}`);
        }
      } catch (error) {
        const errorDetail = getErrorDetail(error as Partial<APIErrorResponse>);
        notificationService.error(`${t(errorMessagePrefix)}: ${errorDetail}`);
      }
    }
  };

  const handlePreviewSuccessful = () => {
    setPreviewCreated(true);
  };

  return (
    <FormModal
      open={open}
      setOpen={setOpen}
      dialogTitle={t('dialogTitleGraphEditor')}
      submitDisabled={
        deviceMetricsLoading ||
        !metrics.length ||
        !!errors.name ||
        !previewCreated ||
        createGraphLoading ||
        (isEditMode && graphLoading) ||
        updateGraphLoading
      }
      onSubmit={handleSubmit(handleFormSubmit)}
      onCancel={() => reset()}
      confirmButtonCaption={isEditMode ? t('buttonSave') : t('buttonConfirm')}
      forceFullscreen
      extraButton={
        !previewCreated ? (
          <ButtonPrimary
            startIcon={<VisibilityIcon />}
            size="small"
            onClick={handlePreviewButtonClick}
            sx={{ textTransform: 'uppercase' }}
          >
            {t('buttonPreview')}
          </ButtonPrimary>
        ) : null
      }
    >
      <Flex flexGrow={1} gap={2}>
        <FlexColumn flex="1" p={1} gap={2}>
          <Typography variant="h6">{t('headingGraphConfiguration')}</Typography>
          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value } }) => (
              <>
                <EllipsisTypography
                  maxWidth="100%"
                  tooltipProps={{ placement: 'top-start' }}
                  color={!errors.name ? palette.primary.light : palette.error.main}
                >
                  {`${t('labelCustomGraphTitle')}*`}
                </EllipsisTypography>
                <TextField value={value} error={!!errors.name} onChange={onChange} />
                {errors.name && (
                  <Typography variant="subtitle2" color="error">
                    {errors.name.message}
                  </Typography>
                )}
              </>
            )}
          />
          <EllipsisTypography
            maxWidth="100%"
            tooltipProps={{ placement: 'top-start' }}
            color={!errors.metrics ? palette.primary.light : palette.error.main}
          >
            {`${t('labelAvailableMetrics')}*`}
          </EllipsisTypography>
          {errors.metrics && (
            <Typography variant="subtitle2" color="error">
              {errors.metrics.message}
            </Typography>
          )}

          {deviceMetricsError ? (
            <AlertWithRefetch onRetryClick={refetch}>
              {t('errorDeviceMetricsLoading')}
            </AlertWithRefetch>
          ) : null}

          <DevicesTree
            setValue={(metricsName, value) => {
              reset((prev) => ({ ...prev, [metricsName]: value }));
            }}
            watch={watch}
            devices={deviceMetrics || []}
            loading={deviceMetricsLoading}
          />
        </FlexColumn>

        <FlexColumn flex="2" p={1}>
          <GraphPreview
            metrics={metrics}
            name={name}
            trigger={trigger}
            ref={graphPreviewRef}
            onPreviewSuccessful={handlePreviewSuccessful}
          />
        </FlexColumn>
      </Flex>
    </FormModal>
  );
};
