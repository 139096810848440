import { Box, Typography } from '@mui/material';
import { t } from '@utils/translate';
import LocationLink from './LocationLink';
import { MapProvider } from './MapProvider';
import SiteOverviewRow from './SiteOverviewRow';
import { SiteOverviewRowItem } from './SiteOverviewRowItem';

interface SiteOverviewPanelProps {
  acPower: number;
  battery: Nullable<number>;
  systemDesign: string;
  commissioning_date: Nullable<string>;
  renewables: string;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
}

const SiteOverviewPanel = ({
  acPower,
  battery,
  systemDesign,
  commissioning_date,
  renewables,
  latitude,
  longitude,
}: SiteOverviewPanelProps) => {
  const items: SiteOverviewRowItem[] = [
    {
      key: 'AC Power',
      value: `${acPower}`,
      unit: 'W',
    },
    {
      key: 'Battery',
      value: battery,
      unit: 'Wh',
    },
    {
      key: 'System Design',
      value: systemDesign,
    },
    {
      key: 'Commissioning date',
      value: commissioning_date,
    },
    {
      key: 'Renewables',
      value: renewables,
    },
  ];

  return (
    <Box
      sx={{
        width: '100%',
        maxWidth: '300px',
        bgcolor: 'primary.main',
        color: 'white',
        paddingX: 2,
        paddingY: 3,
      }}
    >
      <Typography
        variant="h5"
        sx={{
          color: 'white',
          paddingBottom: 1,
        }}
      >
        {t('headingSiteOverview')}
      </Typography>
      <Typography
        sx={{
          color: 'white',
          paddingBottom: 2,
        }}
      >
        The System installed is a 750kw/250kWh containerised Battery Energy Storage System with 1500
        kW of DC coupled solar PV generation.
      </Typography>
      {items.map((item) => (
        <SiteOverviewRow key={item.key} item={item} />
      ))}
      <LocationLink
        mapProvider={MapProvider.GOOGLE_MAPS}
        latitude={latitude}
        longitude={longitude}
      />
    </Box>
  );
};

export default SiteOverviewPanel;
