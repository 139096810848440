import { useEffect, useState } from 'react';
import { useGetOrganizationsAllForSelectQuery } from '@apiRtk/organizations';
import { useUpdateSiteMutation } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api';
import { SiteDto } from '@appTypes/models/site.dto';
import AutocloseAlert from '@components/AutocloseAlert/AutocloseAlert';
import { ButtonCancel, ButtonEdit, ButtonSave } from '@components/Buttons/Buttons';
import { DatePickerField } from '@components/Form/DatePickerField';
import FormViewMode from '@components/FormViewMode/FormViewMode';
import { Flex, GapY } from '@components/LayoutUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCurrentUserInfo } from '@hooks';
import {
  Alert,
  CircularProgress,
  MenuItem,
  Select,
  TextField,
  Typography,
  Box,
} from '@mui/material';
import { getBookmarkedSitesService } from '@services/bookmarkedSites';
import { MAX_BOOKMARKED_SITES } from '@settings/config';
import { t } from '@utils/translate';
import { space } from '@utils/utils';
import { parseISO } from 'date-fns';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { z } from 'zod';
import { siteFormSchema } from './SiteFormSchema';

type FormValues = z.infer<typeof siteFormSchema>;

const defaultValues: FormValues = {
  organization_id: 0,
  name: '',
  street: '',
  city: '',
  postal_code: '',
  country: '',
  lat: '',
  lon: '',
  commissioning_date: new Date(),
};

interface UpdateSiteFormProps {
  siteId: number;
  siteData?: SiteDto;
}

const UpdateSiteForm = ({ siteId, siteData }: UpdateSiteFormProps) => {
  const { isAdmin, isSuperAdmin } = useCurrentUserInfo();

  const { data: organizations, isLoading: isOrganizationsLoading } =
    useGetOrganizationsAllForSelectQuery();

  const [updateSite, { isLoading, isSuccess, isError, error }] = useUpdateSiteMutation();
  const typedError = error as ApiErrorPayload | undefined;

  const bookmarkedSitesService = getBookmarkedSitesService(MAX_BOOKMARKED_SITES);

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(siteFormSchema),
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const onSubmit: SubmitHandler<FormValues> = (data) => {
    bookmarkedSitesService.renameSite(siteId, data.name);

    updateSite({
      ...data,
      siteId,
      organization_id: data.organization_id === 0 ? null : data.organization_id,
      lat: Number(data.lat),
      lon: Number(data.lon),
      commissioning_date: data.commissioning_date?.toISOString(),
    }).then(() => {
      setIsEditMode(false);
    });
  };

  useEffect(() => {
    if (!siteData) {
      return;
    }
    reset({
      organization_id: siteData.organization?.id,
      name: siteData.name,
      street: siteData.address.street,
      city: siteData.address.city,
      postal_code: siteData.address.postal_code,
      country: siteData.address.country,
      lat: String(siteData.coordinates.lat),
      lon: String(siteData.coordinates.lon),
      commissioning_date: siteData.commissioning_date
        ? parseISO(siteData.commissioning_date)
        : undefined,
    });
  }, [siteData, reset]);

  if (isOrganizationsLoading) {
    return <CircularProgress />;
  }

  const fields = siteData
    ? [
        { label: t('labelName'), value: siteData.name },
        { label: t('labelStreet'), value: siteData.address.street },
        { label: t('labelCity'), value: siteData.address.city },
        { label: t('labelPostalCode'), value: siteData.address.postal_code },
        { label: t('labelCountry'), value: siteData.address.country },
        { label: t('labelLatitude'), value: siteData.coordinates.lat },
        { label: t('labelLongitude'), value: siteData.coordinates.lon },
        { label: t('labelOrganization'), value: siteData.organization?.name },
        { label: t('labelCommissioningDate'), value: siteData.commissioning_date },
      ]
    : [];

  return (
    <Box>
      {isEditMode ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="name"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelName')}
                value={value}
                error={!!errors.name}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.name && (
            <Typography variant="subtitle2" color="error">
              {errors.name.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="street"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelStreet')}
                value={value}
                error={!!errors.street}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.street && (
            <Typography variant="subtitle2" color="error">
              {errors.street.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="city"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelCity')}
                value={value}
                error={!!errors.city}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.city && (
            <Typography variant="subtitle2" color="error">
              {errors.city.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="postal_code"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelPostalCode')}
                value={value}
                error={!!errors.postal_code}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.postal_code && (
            <Typography variant="subtitle2" color="error">
              {errors.postal_code.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="country"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelCountry')}
                value={value}
                error={!!errors.country}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.country && (
            <Typography variant="subtitle2" color="error">
              {errors.country.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="lat"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelLatitude')}
                value={value}
                error={!!errors.lat}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.lat && (
            <Typography variant="subtitle2" color="error">
              {errors.lat.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="lon"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <TextField
                label={t('labelLongitude')}
                value={value}
                error={!!errors.lon}
                sx={{ width: '100%' }}
                onChange={onChange}
                disabled={!isEditMode}
              />
            )}
          />
          {errors.lon && (
            <Typography variant="subtitle2" color="error">
              {errors.lon.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="organization_id"
            control={control}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <Select
                label={t('labelOrganization')}
                sx={{ width: '100%' }}
                value={value}
                error={!!errors.organization_id}
                onChange={onChange}
                disabled={!isEditMode}
              >
                <MenuItem key={0} value={0}>
                  -
                </MenuItem>
                {(organizations || []).map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
          {errors.organization_id && (
            <Typography variant="subtitle2" color="error">
              {errors.organization_id.message}
            </Typography>
          )}

          <GapY size={2} />

          <Controller
            name="commissioning_date"
            control={control}
            render={({ field: { onChange, value } }) => (
              <DatePickerField
                label={t('labelCommissioningDate')}
                value={value}
                onChange={onChange}
                fullWidth
              />
            )}
          />
          {errors.commissioning_date && (
            <Typography variant="subtitle2" color="error">
              {errors.commissioning_date.message}
            </Typography>
          )}

          <GapY size={2} />

          <Flex gap={1}>
            <ButtonSave size="large" type="submit" disabled={isLoading} />
            <ButtonCancel onClick={() => setIsEditMode(false)} disabled={isLoading} />
          </Flex>
        </form>
      ) : (
        <Box>
          {siteData && <FormViewMode fields={fields} />}
          {(isAdmin || isSuperAdmin) && (
            <Flex justifyContent="right">
              <ButtonEdit onClick={() => setIsEditMode(true)} />
            </Flex>
          )}
        </Box>
      )}
      {isError && (
        <>
          <Alert severity="error">
            {typedError && typeof typedError?.data?.detail === 'string'
              ? typedError.data.detail
              : t('errorCommonAction')}
          </Alert>
          <GapY size={2} />
        </>
      )}
      {isSuccess && (
        <AutocloseAlert severity="success" message="Saved" sx={{ marginTop: space(1) }} />
      )}
    </Box>
  );
};

export default UpdateSiteForm;
