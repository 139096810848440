import { useCreateOrganizationMutation } from '@apiRtk/organizations';
import { ApiErrorPayload } from '@appTypes/api';
import { ButtonSave } from '@components/Buttons/Buttons';
import { EllipsisTypography } from '@components/EllipsisTypography';
import { CenterY, GapY } from '@components/LayoutUtils';
import { zodResolver } from '@hookform/resolvers/zod';
import { TextField, Typography } from '@mui/material';
import { notificationService } from '@services/notificationService/notificationService';
import { t } from '@utils/translate';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { palette } from 'styles/palette';
import { z } from 'zod';
import organizationFormSchema from './OrganizationFormSchema';

type FormValues = z.infer<typeof organizationFormSchema>;

const defaultValues: FormValues = {
  name: '',
  manager_name: '',
  manager_email: '',
  manager_phone: '',
  street: '',
  city: '',
  postal_code: '',
  country: '',
};

const CreateOrganizationForm = () => {
  const [createOrganization, { isLoading }] = useCreateOrganizationMutation();

  const {
    handleSubmit,
    control,
    formState: { errors },
    reset,
  } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
    resolver: zodResolver(organizationFormSchema),
  });

  const onSubmit: SubmitHandler<FormValues> = async (data) => {
    try {
      await createOrganization(data).unwrap();

      notificationService.success(t('successOrganizationCreated'));
      reset();
    } catch (err) {
      const typedError = err as ApiErrorPayload;
      notificationService.error(
        typedError && typeof typedError?.data?.detail === 'string'
          ? typedError.data.detail
          : t('errorCommonAction'),
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Controller
        name="name"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.name ? palette.primary.light : palette.error.main}
            >
              {`${t('labelOrganizationName')} *`}
            </EllipsisTypography>
            <TextField value={value} error={!!errors.name} fullWidth onChange={onChange} />
            {errors.name && (
              <Typography variant="subtitle2" color="error">
                {errors.name.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="manager_name"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.manager_name ? palette.primary.light : palette.error.main}
            >
              {`${t('labelManagerName')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.manager_name}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.manager_name && (
              <Typography variant="subtitle2" color="error">
                {errors.manager_name.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="manager_email"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.manager_email ? palette.primary.light : palette.error.main}
            >
              {`${t('labelEmail')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.manager_email}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.manager_email && (
              <Typography variant="subtitle2" color="error">
                {errors.manager_email.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="manager_phone"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.manager_phone ? palette.primary.light : palette.error.main}
            >
              {`${t('labelPhone')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.manager_phone}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.manager_phone && (
              <Typography variant="subtitle2" color="error">
                {errors.manager_phone.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="street"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.street ? palette.primary.light : palette.error.main}
            >
              {`${t('labelStreet')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.street}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.street && (
              <Typography variant="subtitle2" color="error">
                {errors.street.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="city"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.city ? palette.primary.light : palette.error.main}
            >
              {`${t('labelCity')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.city}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.city && (
              <Typography variant="subtitle2" color="error">
                {errors.city.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="postal_code"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.postal_code ? palette.primary.light : palette.error.main}
            >
              {`${t('labelPostalCode')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.postal_code}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.postal_code && (
              <Typography variant="subtitle2" color="error">
                {errors.postal_code.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <Controller
        name="country"
        control={control}
        render={({ field: { onChange, value } }) => (
          <>
            <EllipsisTypography
              maxWidth="100%"
              tooltipProps={{ placement: 'top-start' }}
              color={!errors.country ? palette.primary.light : palette.error.main}
            >
              {`${t('labelCountry')} *`}
            </EllipsisTypography>
            <TextField
              value={value}
              error={!!errors.country}
              sx={{ width: '100%' }}
              onChange={onChange}
            />
            {errors.country && (
              <Typography variant="subtitle2" color="error">
                {errors.country.message}
              </Typography>
            )}
          </>
        )}
      />

      <GapY size={2} />

      <CenterY gap={3}>
        <ButtonSave size="large" type="submit" disabled={isLoading} />
      </CenterY>
    </form>
  );
};

export default CreateOrganizationForm;
