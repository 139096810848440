import { FEATURE_FLAGS } from '@appTypes/common';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import { dictionary, DictionaryKeys } from '@settings/dictionary';
import frontendAdminTools from '../../locale/en/frontend-admin-tools.json';
import frontendCommon from '../../locale/en/frontend-common.json';
import frontendGlobalAlarms from '../../locale/en/frontend-global-alarms.json';
import frontendGlobalConfiguration from '../../locale/en/frontend-global-configuration.json';
import frontendGlobalNotifications from '../../locale/en/frontend-global-notifications.json';
import frontendSiteAlarms from '../../locale/en/frontend-site-alarms.json';
import frontendSiteConfiguration from '../../locale/en/frontend-site-configuration.json';
import frontendSiteDataAnalysis from '../../locale/en/frontend-site-data-analysis.json';
import frontendSiteNotifications from '../../locale/en/frontend-site-notifications.json';
import frontendSiteOverview from '../../locale/en/frontend-site-overview.json';
import frontendSites from '../../locale/en/frontend-sites.json';

type TranslationDictionary = Record<string, string>;

const getNamespace = (): TranslationDictionary => {
  if (window.location.pathname === '/admin-tools') {
    return frontendAdminTools;
  }

  if (window.location.pathname === '/alarms') {
    return frontendGlobalAlarms;
  }

  if (window.location.pathname === '/configuration') {
    return frontendGlobalConfiguration;
  }

  if (window.location.pathname === '/notifications') {
    return frontendGlobalNotifications;
  }

  if (window.location.pathname === '/sites') {
    return frontendSites;
  }

  if (window.location.pathname.match('/sites/[0-9]+/overview')) {
    return frontendSiteOverview;
  }

  if (window.location.pathname.match('/sites/[0-9]+/data-analysis')) {
    return frontendSiteDataAnalysis;
  }

  if (window.location.pathname.match('/sites/[0-9]+/alarms')) {
    return frontendSiteAlarms;
  }

  if (window.location.pathname.match('/sites/[0-9]+/notifications')) {
    return frontendSiteNotifications;
  }

  if (window.location.pathname.match('/sites/[0-9]+/configuration')) {
    return frontendSiteConfiguration;
  }
  return {};
};

const getCommonNamespace = (): TranslationDictionary => frontendCommon;

const getTranslation = (key: string, namespace: TranslationDictionary) =>
  namespace[key] || getCommonNamespace()[key];

/**
 * Translation function
 *   - `key` must be one of the known keys in `dictionary`.
 *   - `params` is an object used to replace {{placeholders}}
 */
export function t(key: string, params?: Record<string, any>): string {
  let text = '';

  if (isFeatureFlagEnabled(FEATURE_FLAGS.TRANSLATION_NAMESPACES)) {
    const namespace = getNamespace();

    text = getTranslation(key, namespace);
  } else {
    text = dictionary[key as DictionaryKeys];
  }

  if (!text) {
    return key;
  }

  if (params) {
    Object.entries(params).forEach(([placeholder, value]) => {
      text = text.replace(new RegExp(`{{${placeholder}}}`, 'g'), String(value));
    });
  }

  return text;
}
