import { useEffect, useState } from 'react';
import { useUpdateSiteOptimizationMutation } from '@apiRtk/sites';
import { ApiErrorPayload } from '@appTypes/api';
import { SiteOptimizationDto } from '@appTypes/models/site.dto';
import AutocloseAlert from '@components/AutocloseAlert/AutocloseAlert';
import { ButtonSave, ButtonCancel, ButtonEdit } from '@components/Buttons/Buttons';
import FormViewMode from '@components/FormViewMode/FormViewMode';
import { Flex, GapY } from '@components/LayoutUtils';
import { CircularProgress, FormControlLabel, MenuItem, Select, Switch, Box } from '@mui/material';
import { t } from '@utils/translate';
import { space } from '@utils/utils';
import { Control, Controller, SubmitHandler, useForm } from 'react-hook-form';

interface FormValues {
  enabled: boolean;
  optimizationType: string;
  optimizationScope: string;
  optimizationCurrency: string;
}

interface UpdateSiteFormProps {
  siteId: number;
  data?: SiteOptimizationDto;
  formEnabled: boolean;
}

const defaultValues: FormValues = {
  enabled: false,
  optimizationType: '',
  optimizationScope: '',
  optimizationCurrency: '',
};

const optimizationTypeOptions = [
  { value: 'hic', label: t('optionHicMode') },
  { value: 'dynamic', label: t('optionFullyDynamicMode') },
  { value: 'nominations', label: t('optionNominationsMode') },
  { value: 'other', label: t('optionOther') },
];

const optimizationScopeOptions = [
  { value: 'import', label: t('optionImportOptimisation') },
  { value: 'import_export', label: t('optionImportExportOptimisation') },
];

const optimizationCurrencyOptions = [
  { value: 'eur', label: t('optionEur') },
  { value: 'gbp', label: t('optionGbp') },
];

const valueLabelMapping = {
  optimizationType: Object.fromEntries(
    optimizationTypeOptions.map((option) => [option.value, option.label]),
  ),
  optimizationScope: Object.fromEntries(
    optimizationScopeOptions.map((option) => [option.value, option.label]),
  ),
  optimizationCurrency: Object.fromEntries(
    optimizationCurrencyOptions.map((option) => [option.value, option.label]),
  ),
};

const RenderSelectField = ({
  name,
  label,
  options,
  control,
  formEnabled,
  enabled,
}: {
  name: keyof FormValues;
  label: string;
  options: { value: string; label: string }[];
  control: Control<FormValues>;
  formEnabled: boolean;
  enabled: boolean;
}) => (
  <Controller
    name={name}
    control={control}
    rules={{ required: true }}
    render={({ field: { onChange, value } }) => (
      <Select
        label={label}
        sx={{ width: '100%' }}
        value={value}
        onChange={onChange}
        disabled={!formEnabled || !enabled}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    )}
  />
);

const SiteOptimizationForm = ({ siteId, data, formEnabled }: UpdateSiteFormProps) => {
  const [updateOptimization, { isLoading, isSuccess, isError, error }] =
    useUpdateSiteOptimizationMutation();
  const typedError = error as ApiErrorPayload | undefined;

  const { handleSubmit, control, reset, watch, getValues } = useForm<FormValues>({
    mode: 'onChange',
    defaultValues,
  });

  const [isEditMode, setIsEditMode] = useState(false);

  const enabled = watch('enabled');

  const fields = data
    ? [
        {
          label: t('labelOptimizationType'),
          value: valueLabelMapping.optimizationType[data.optimizationType],
        },
        {
          label: t('labelOptimizationScope'),
          value: valueLabelMapping.optimizationScope[data.optimizationScope],
        },
        {
          label: t('labelOptimizationCurrency'),
          value: valueLabelMapping.optimizationCurrency[data.optimizationCurrency],
        },
      ]
    : [];

  useEffect(() => {
    if (!data) {
      return;
    }
    reset({
      enabled: data.enabled,
      optimizationType: data.optimizationType,
      optimizationScope: data.optimizationScope,
      optimizationCurrency: data.optimizationCurrency,
    });
  }, [data, reset]);

  useEffect(() => {
    if (!formEnabled) {
      setIsEditMode(false);
    }
  }, [formEnabled]);

  const onSubmit: SubmitHandler<FormValues> = (formData) => {
    updateOptimization({
      siteId,
      ...formData,
    }).then(() => {
      setIsEditMode(false);
    });
  };

  const handleToggleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newEnabled = event.target.checked;
    const { optimizationType, optimizationScope, optimizationCurrency } = getValues();
    updateOptimization({
      siteId,
      enabled: newEnabled,
      optimizationType,
      optimizationScope,
      optimizationCurrency,
    });
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box>
      <Controller
        name="enabled"
        control={control}
        render={({ field: { onChange, value } }) => (
          <FormControlLabel
            control={
              <Switch
                size="small"
                checked={value}
                onChange={(e) => {
                  onChange(e);
                  handleToggleChange(e);
                }}
                disabled={!formEnabled}
              />
            }
            label={t('labelEnableForThisSite')}
          />
        )}
      />

      <GapY size={2} />

      {isEditMode ? (
        <form onSubmit={handleSubmit(onSubmit)}>
          <RenderSelectField
            name="optimizationType"
            label={t('labelOptimizationType')}
            options={optimizationTypeOptions}
            control={control}
            formEnabled={formEnabled}
            enabled={enabled}
          />

          <GapY size={2} />

          <RenderSelectField
            name="optimizationScope"
            label={t('labelOptimizationScope')}
            options={optimizationScopeOptions}
            control={control}
            formEnabled={formEnabled}
            enabled={enabled}
          />

          <GapY size={2} />

          <RenderSelectField
            name="optimizationCurrency"
            label={t('labelOptimizationCurrency')}
            options={optimizationCurrencyOptions}
            control={control}
            formEnabled={formEnabled}
            enabled={enabled}
          />

          <GapY size={2} />

          <Flex gap={1}>
            <ButtonSave
              size="large"
              type="submit"
              disabled={!formEnabled || !enabled || isLoading}
            />
            <ButtonCancel onClick={() => setIsEditMode(false)} disabled={isLoading} />
          </Flex>
        </form>
      ) : (
        <Box>
          {data && <FormViewMode fields={fields} disabled={!enabled} />}
          <Flex justifyContent="right">
            <ButtonEdit onClick={() => setIsEditMode(true)} disabled={!enabled} />
          </Flex>
        </Box>
      )}
      {isError && (
        <AutocloseAlert
          severity="error"
          message={
            typedError && typeof typedError.data?.detail === 'string'
              ? typedError.data.detail
              : t('errorCommonAction')
          }
        />
      )}
      {isSuccess && (
        <AutocloseAlert
          severity="success"
          message={t('successSaved')}
          sx={{ marginTop: space(1) }}
        />
      )}
    </Box>
  );
};

export default SiteOptimizationForm;
