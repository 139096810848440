import { ApiError } from '@appTypes/api';
import { EcdFilters } from '@appTypes/ecds/filters';
import {
  ActionType,
  RequestSuccessActionType,
  SetErrorActionType,
  SetLoadingActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import {
  EcdDto,
  EcdSimpleDto,
  EcdSimpleRemoteDto,
  EcdWithErrorDto,
  UpdateEcdDto,
} from '@appTypes/models/ecd.dto';

export enum EcdsActionsTypes {
  SET_ECD_REQUEST_ERROR = 'SET_ECD_REQUEST_ERROR',
  SET_ECD_REQUEST_LOADING = 'SET_ECD_REQUEST_LOADING',
  CLEAR_ECD_CURRENT = 'CLEAR_ECD_CURRENT',
  FETCHED_ECD = 'FETCHED_ECD',
  GET_ECD_SUCCESS = 'GET_ECD_SUCCESS',
  REQUEST_ECDS = 'REQUEST_ECDS',
  REQUEST_ECDS_SUCCESS = 'REQUEST_ECDS_SUCCESS',
  REQUEST_ECDS_FAILED = 'REQUEST_ECDS_FAILED',
  FETCHED_ECDS = 'FETCHED_ECDS',
  FETCHED_ECDS_COUNT = 'FETCHED_ECDS_COUNT',
  UPDATE_ECDS_FILTER = 'UPDATE_ECDS_FILTER',
  FETCHED_UPDATE_ECD = 'FETCHED_UPDATE_ECD',
  UPDATE_ECD_SUCCESS = 'UPDATE_ECD_SUCCESS',
  FETCHED_ECDS_STATUSES = 'FETCHED_ECDS_STATUSES',
  REQUEST_ECD_STATUS = 'REQUEST_ECD_STATUS',
  REQUEST_ECD_STATUS_SUCCESS = 'REQUEST_ECD_STATUS_SUCCESS',
  REQUEST_ECD_STATUS_FAILED = 'REQUEST_ECD_STATUS_FAILED',
}

export type FetchedEcdsCount = ActionType<EcdsActionsTypes.FETCHED_ECDS_COUNT>;

export type UpdateEcdSuccess = ActionType<EcdsActionsTypes.UPDATE_ECD_SUCCESS, EcdDto>;

export type FetchedUpdateEcd = ActionType<EcdsActionsTypes.FETCHED_UPDATE_ECD, UpdateEcdDto>;

export type FetchedGetEcd = ActionType<EcdsActionsTypes.FETCHED_ECD, EcdSimpleDto['id']>;

export type GetEcdSuccess = ActionType<EcdsActionsTypes.GET_ECD_SUCCESS, EcdWithErrorDto>;

export type ClearEcdCurrent = ActionType<EcdsActionsTypes.CLEAR_ECD_CURRENT>;

export type SetEcdRequestError = SetErrorActionType<EcdsActionsTypes.SET_ECD_REQUEST_ERROR>;

export type SetEcdRequestLoading = SetLoadingActionType<EcdsActionsTypes.SET_ECD_REQUEST_LOADING>;

export type RequestEcdsSuccess = RequestSuccessActionType<
  EcdsActionsTypes.REQUEST_ECDS_SUCCESS,
  EcdSimpleDto
>;

export type RequestEcdsRemoteSuccess = RequestSuccessActionType<
  EcdsActionsTypes.REQUEST_ECDS_SUCCESS,
  EcdSimpleRemoteDto
>;

export type RequestEcds = ActionType<EcdsActionsTypes.REQUEST_ECDS>;

export type RequestEcdsFailed = ActionType<EcdsActionsTypes.REQUEST_ECDS_FAILED, ApiError>;

export type FetchedEcds = ActionType<
  EcdsActionsTypes.FETCHED_ECDS,
  { page: number; pageSize: number }
>;

export type UpdateEcdsFilter = UpdateFilterActionType<
  EcdsActionsTypes.UPDATE_ECDS_FILTER,
  EcdFilters
>;

export type FetchedEcdsStatuses = ActionType<EcdsActionsTypes.FETCHED_ECDS_STATUSES, number>;

export type RequestEcdStatus = ActionType<EcdsActionsTypes.REQUEST_ECD_STATUS, EcdSimpleDto['id']>;

export type RequestEcdStatusSuccess = ActionType<
  EcdsActionsTypes.REQUEST_ECD_STATUS_SUCCESS,
  { id: EcdSimpleDto['id']; alive: boolean }
>;

export type RequestEcdStatusFailed = ActionType<
  EcdsActionsTypes.REQUEST_ECD_STATUS_FAILED,
  { id: EcdSimpleDto['id']; error: ApiError }
>;
