import React, { useEffect, useState } from 'react';
import { parseBigNumber } from '@components/Charts/helpers';
import { CenterX } from '@components/LayoutUtils';
import { Card as CommonCard, Typography, styled } from '@mui/material';
import { t } from '@utils/translate';
import { palette } from 'styles/palette';
import { degreesToRadians, isOnRightSide } from '../helpers';
import { DeviceParameterWithName, DiagramConfigItem } from '../types';
import Wire, { WireProps } from './Wire';

const Wrapper = styled('div')<{ animate: boolean }>`
  opacity: ${({ animate }) => (animate ? 0 : 1)};
  transform: ${({ animate }) => (animate ? 'scale(0.2)' : 'scale(1)')};

  transition: 1s all cubic-bezier(0.34, 1.56, 0.64, 1);
  transition-property: opacity transform;
`;

const CardsWrapper = styled('div')`
  display: flex;
`;

const Relative = styled('div')`
  position: relative;
`;

const Card = styled(CommonCard)`
  position: relative;
  border-radius: 0;
  border: 1px solid ${({ theme }) => theme.palette.primary.main};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;

  &:first-of-type {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  &:last-of-type {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    margin-left: -1px;
  }
`;

const CardValue = styled(Typography)`
  margin-top: auto;
  margin-bottom: auto;
  color: ${({ theme }) => theme.palette.primary.main};
  font-weight: bold;
`;

const CardUnit = styled(Typography)`
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  font-weight: normal;
  padding-top: 2px;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${palette.white};
  width: 100%;
  font-size: 14px;
  text-align: center;
`;

const Title = styled(Typography)`
  font-size: 12px;
  margin-top: 4px;
  color: ${({ theme }) => theme.palette.primary.main};
  position: absolute;
`;

const Content = styled('div')<{ translateX: number; translateY: number }>`
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;

  -webkit-transform: translate(
    ${({ translateX, translateY }) => `${translateX}px, ${translateY}px`}
  );

  transform: translate(${({ translateX, translateY }) => `${translateX}px, ${translateY}px`});
`;

type DeviceNodeProps = WireProps &
  React.HTMLAttributes<HTMLDivElement> & {
    parameters: DeviceParameterWithName[];
    IconComponent: DiagramConfigItem['IconComponent'];
    title: string;
    cardSize?: number;
  };

const DeviceNode = ({
  cardSize = 46,
  title,
  className,
  parameters,
  IconComponent,
  style,
  rotation = 0,
  ...wireProps
}: DeviceNodeProps) => {
  const { wireLength = 100 } = wireProps;
  const rtl = isOnRightSide(rotation);

  const [animate, setAnimate] = useState(true);

  useEffect(() => {
    setAnimate(false);
  }, []);

  const wireThickness = cardSize * 0.7;
  const offsetX = cardSize / 2;
  const offsetY = (cardSize - wireThickness) / 2;

  const translateX = wireLength * Math.cos(degreesToRadians(rotation));
  const translateY = wireLength * Math.sin(degreesToRadians(rotation));

  return (
    <Wrapper className={className} style={style} animate={animate}>
      <Relative>
        <Wire
          style={{
            position: 'absolute',
            left: offsetX,
            top: offsetY,
          }}
          rotation={rotation}
          wireThickness={wireThickness}
          {...wireProps}
        />
        <Content
          style={{
            width: cardSize,
            height: cardSize,
          }}
          translateX={translateX}
          translateY={translateY}
        >
          <div
            style={{
              position: 'absolute',
              left: rtl ? 0 : 'initial',
              right: rtl ? 'initial' : 0,
            }}
          >
            <CenterX
              style={{
                flexDirection: rtl ? 'row-reverse' : 'row',
                justifyContent: 'flex-start',
                gap: '8px',
              }}
            >
              {IconComponent && <IconComponent width={cardSize * 0.8} height={cardSize * 0.8} />}
              <CardsWrapper>
                {parameters?.map(({ value, unit, name }) => {
                  const absoluteValue = Math.abs(value || 0);
                  const [parsedValue, parsedUnit] = parseBigNumber(absoluteValue, unit, 1);

                  return (
                    <Card
                      elevation={0}
                      key={name}
                      style={{
                        width: cardSize,
                        height: cardSize,
                        containerType: 'inline-size',
                      }}
                    >
                      <CardValue
                        style={{
                          fontSize: '30cqw',
                        }}
                      >
                        {value === null ? '-' : parsedValue}
                      </CardValue>
                      <CardUnit
                        data-testid="card-unit"
                        style={{
                          fontSize: '20cqw',
                        }}
                      >
                        {parsedUnit}
                      </CardUnit>
                    </Card>
                  );
                })}
              </CardsWrapper>
            </CenterX>
            <Title data-testid="node-title">{t(title)}</Title>
          </div>
        </Content>
      </Relative>
    </Wrapper>
  );
};

export default DeviceNode;
