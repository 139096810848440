import ErrorBanner from '@components/ErrorBanner/ErrorBanner';
import { CenterXY } from '@components/LayoutUtils';
import { ErrorBoundary } from '@sentry/react';

const GlobalErrorBoundary = ({ children }: React.PropsWithChildren) => (
  <ErrorBoundary
    fallback={
      <CenterXY height="100vh">
        <ErrorBanner errorCode={500} />
      </CenterXY>
    }
    beforeCapture={(scope) => {
      scope.setLevel('fatal');
    }}
  >
    {children}
  </ErrorBoundary>
);

export default GlobalErrorBoundary;
