import { ApiError } from '@appTypes/api';
import {
  ActionType,
  RequestSuccessActionType,
  SetErrorActionType,
  SetLoadingActionType,
  UpdateFilterActionType,
} from '@appTypes/helpers/redux';
import { CreateUserDto, UserDto, UserSimpleDto } from '@appTypes/models/user.dto';
import { UserFilters } from '@appTypes/users/filters';

export enum UserActionsType {
  REQUEST_USERS = 'REQUEST_USERS',
  FETCHED_USERS = 'FETCHED_USERS',
  FETCHED_USERS_COUNT = 'FETCHED_USERS_COUNT',
  SET_USER_REQUEST_ERROR = 'SET_USER_REQUEST_ERROR',
  SET_USER_REQUEST_LOADING = 'SET_USER_REQUEST_LOADING',
  REQUEST_USERS_SUCCESS = 'REQUEST_USERS_SUCCESS',
  REQUEST_USERS_FAILED = 'REQUEST_USERS_FAILED',
  FETCHED_UPDATE_USER = 'FETCHED_UPDATE_USER',
  FETCHED_GET_USER = 'FETCHED_GET_USER',
  UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS',
  FETCHED_CREATE_USER = 'FETCHED_CREATE_USER',
  UPDATE_USER_FILTER = 'UPDATE_USER_FILTER',
  CLEAR_USER_CURRENT = 'CLEAR_USER_CURRENT',
  UPDATE_USERS_FILTER = 'UPDATE_USERS_FILTER',
  GET_USER_SUCCESS = 'GET_USER_SUCCESS',
  CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS',
}

export type ClearUserCurrent = ActionType<UserActionsType.CLEAR_USER_CURRENT>;

export type FetchedUsersCount = ActionType<UserActionsType.FETCHED_USERS_COUNT>;

export type SetUserRequestError = SetErrorActionType<UserActionsType.SET_USER_REQUEST_ERROR>;

export type SetUserRequestLoading = SetLoadingActionType<UserActionsType.SET_USER_REQUEST_LOADING>;

export type UpdateUsersFilter = UpdateFilterActionType<
  UserActionsType.UPDATE_USER_FILTER,
  UserFilters
>;

export type RequestUsers = ActionType<UserActionsType.REQUEST_USERS>;

export type RequestUsersSuccess = RequestSuccessActionType<
  UserActionsType.REQUEST_USERS_SUCCESS,
  UserSimpleDto
>;

export type FetchedUsers = ActionType<
  UserActionsType.FETCHED_USERS,
  { page: number; pageSize: number }
>;

export type RequestUsersFailed = ActionType<UserActionsType.REQUEST_USERS_FAILED, ApiError>;

export type GetUserSuccess = ActionType<UserActionsType.GET_USER_SUCCESS, UserDto>;

export type FetchedGetUser = ActionType<UserActionsType.FETCHED_GET_USER, number>;

export type CreateUserSuccess = ActionType<UserActionsType.CREATE_USER_SUCCESS, UserDto>;

export type FetchedCreateUser = ActionType<UserActionsType.FETCHED_CREATE_USER, CreateUserDto>;

export type UpdateUserSuccess = ActionType<UserActionsType.UPDATE_USER_SUCCESS, UserDto>;
