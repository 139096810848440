import { FEATURE_FLAGS } from '@appTypes/common';
import { AppWrapper } from '@components/AppWrapper/AppWrapper';
import GlobalErrorBoundary from '@components/GlobalErrorBoundary/GlobalErrorBoundary';
import MatomoTracker from '@components/MatomoTracker/MatomoTracker';
import CONFIG from '@config';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { PermissionProvider } from '@providers/PermissionProvider';
import { store } from '@redux/store';
import { snackbarProviderProps } from '@services/AlertService';
import { isFeatureFlagEnabled } from '@services/featureFlags';
import logger from '@services/logger';
import { NotificationListener } from '@services/notificationService/NotificationListener';
import { wattstorTheme } from '@theme';
import { isDevelopment, isProduction } from '@utils/utils';
import { SnackbarProvider } from 'notistack';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';

logger.init();

window.global ||= window;

document.title = `Podium v${CONFIG.APP_VERSION}`;
const root = ReactDOM.createRoot(document.getElementById('root')!);

async function enableMocking() {
  if (isDevelopment() && isFeatureFlagEnabled(FEATURE_FLAGS.ADVANCED_DATA_EXPORT)) {
    const { worker } = await import('./__mocks__/api/worker');
    await worker.start({ onUnhandledRequest: 'bypass' });
  }
}

enableMocking().then(() => {
  root.render(
    <CssVarsProvider theme={wattstorTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <PermissionProvider>
          <Provider store={store}>
            <GlobalErrorBoundary>
              <HelmetProvider>
                <SnackbarProvider {...snackbarProviderProps}>
                  {isProduction() ? <MatomoTracker /> : null}
                  <AppWrapper />
                </SnackbarProvider>
              </HelmetProvider>
            </GlobalErrorBoundary>
          </Provider>
        </PermissionProvider>
      </LocalizationProvider>
      <NotificationListener />
    </CssVarsProvider>,
  );
});
