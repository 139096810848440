import { GraphDto } from '@appTypes/models/site.dto';
import { SECONDS_MINUTE } from '@settings/constants';
import { convertLocalDateToApiString, createParams } from '@utils/utils';
import { coreApi } from './apiWattstor';

type GraphArgs = {
  dataLink: string;
  startDate: Date;
  endDate: Date;
  limit: number;
};

export type PreviewParams = {
  siteId: number;
};

export type PreviewPayload = {
  name: string;
  items: string[];
};

type PreviewArgs = PreviewParams & PreviewPayload;
type CreateArgs = PreviewArgs;
type UpdateArgs = PreviewPayload & {
  editLink: string;
};

const graphsApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getGraph: builder.query<GraphDto, GraphArgs>({
      query: ({ dataLink, startDate, endDate, limit }) =>
        `${dataLink}?${createParams({
          limit,
          start: convertLocalDateToApiString(startDate),
          end: convertLocalDateToApiString(endDate),
        })}`,
      keepUnusedDataFor: SECONDS_MINUTE,
      providesTags: ['GraphData'],
    }),
    getCustomGraphPreview: builder.mutation<GraphDto, PreviewArgs>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}/analytics/preview`,
        method: 'POST',
        data: { ...payload },
      }),
    }),
    createCustomGraph: builder.mutation<void, CreateArgs>({
      query: ({ siteId, ...payload }) => ({
        url: `sites/${siteId}/analytics/preview/create`,
        method: 'POST',
        data: { ...payload },
      }),
      invalidatesTags: () => ['Analytics'],
    }),
    updateCustomGraph: builder.mutation<void, UpdateArgs>({
      query: ({ editLink: updateLink, ...payload }) => ({
        url: updateLink,
        method: 'PUT',
        data: { ...payload },
      }),
      invalidatesTags: () => ['Analytics', 'GraphData'],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetGraphQuery,
  useGetCustomGraphPreviewMutation,
  useCreateCustomGraphMutation,
  useUpdateCustomGraphMutation,
} = graphsApi;
