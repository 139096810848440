import { Flex } from '@components/LayoutUtils';
import { OpenInNew, Place } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { t } from '@utils/translate';
import { MapProvider } from './MapProvider';

interface LocationLinkProps {
  mapProvider: MapProvider;
  latitude: Nullable<number>;
  longitude: Nullable<number>;
}

const LocationLink = ({ mapProvider, latitude, longitude }: LocationLinkProps) => {
  const isValidCoordinates =
    !!latitude &&
    !!longitude &&
    latitude >= -90 &&
    latitude <= 90 &&
    longitude >= -180 &&
    longitude <= 180;

  const getMapUrl = (provider: MapProvider, lat: number, lon: number) => {
    switch (provider) {
      case MapProvider.GOOGLE_MAPS:
        return `https://www.google.com/maps/search/?api=1&query=${lat},${lon}`;
      case MapProvider.MAPY_CZ:
        return `https://mapy.cz/zakladni?x=${lon}&y=${lat}&z=15&source=coor&id=${lon},${lat}`;
      default:
        return '#';
    }
  };

  if (!isValidCoordinates) return null;

  return (
    <a
      href={getMapUrl(mapProvider, latitude, longitude)}
      target="_blank"
      rel="noreferrer"
      style={{
        color: 'white',
        textDecoration: 'none',
      }}
    >
      <Flex
        alignItems="center"
        fontWeight="bold"
        sx={{
          pt: 1,
          '&:hover': {
            textDecoration: 'underline',
          },
        }}
      >
        <Place sx={{ pr: 1 }} />
        <Typography
          sx={{
            color: 'white',
            fontSize: '14px',
            fontWeight: 'bold',
          }}
        >
          {t('labelViewLocationOnMap')}
        </Typography>
        <OpenInNew sx={{ pl: '4px', width: 14, height: 14 }} />
      </Flex>
    </a>
  );
};

export default LocationLink;
