import { HeadersResponse } from '@appTypes/models/headers.dto';
import { coreApi } from './apiWattstor';
import { impsApi } from './imps';
import { organizationsApi } from './organizations';
import { sitesApi } from './sites';
import { usersApi } from './users';

const headersApi = coreApi.injectEndpoints({
  endpoints: (builder) => ({
    getHeaders: builder.query<HeadersResponse, null>({
      queryFn: async (_arg, { dispatch }) => {
        const [sitesResult, ecdResult, organizationResult, userResult] = await Promise.all([
          dispatch(
            sitesApi.endpoints.getSites.initiate({ page_size: 10, page: 1, descending: false }),
          ),
          dispatch(
            impsApi.endpoints.getEcds.initiate({ page_size: 10, page: 1, descending: false }),
          ),
          dispatch(
            organizationsApi.endpoints.getOrganizations.initiate({
              page_size: 10,
              page: 1,
              descending: false,
            }),
          ),
          dispatch(usersApi.endpoints.getUsers.initiate()),
        ]);

        const sitesResponse = sitesResult.data;
        const ecdResponse = ecdResult.data;
        const organizationResponse = organizationResult.data;
        const userResponse = userResult.data;

        const headersResponse: HeadersResponse = {
          siteCount: sitesResponse ? sitesResponse.count : 0,
          ecdCount: ecdResponse ? ecdResponse.count : 0,
          organizationCount: organizationResponse ? organizationResponse.count : 0,
          userCount: userResponse ? userResponse.count : 0,
        };

        return { data: headersResponse };
      },
      providesTags: ['Headers'],
    }),
  }),
  overrideExisting: false,
});

export const { useGetHeadersQuery } = headersApi;
