import { useState } from 'react';
import { useGetUserQuery } from '@apiRtk/users';
import { ApiErrorPayload } from '@appTypes/api';
import { ButtonBack } from '@components/Buttons/Buttons';
import { ConfiguredItem } from '@components/ConfigurationList/types';
import { CenterX, GapY } from '@components/LayoutUtils';
import { usePalette } from '@hooks/usePalette';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {
  Alert,
  CircularProgress,
  Typography,
  Grid,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
} from '@mui/material';
import { t } from '@utils/translate';
import { paths } from 'paths';
import { useParams } from 'react-router-dom';
import UpdateUserForm from './UpdateUserForm';
import SiteList from './UserForm/Relations/SiteList';
import { SiteRelationModal } from './UserForm/Relations/SiteRelationModal';

const UpdateUserPage = () => {
  const palette = usePalette();

  const params = useParams();
  const id = params.id as string;
  const userId = parseInt(id, 10);

  const [userFormExpanded, setUserFormExpanded] = useState(true);
  const [siteRelationModalOpen, setSiteRelationModalOpen] = useState(false);

  const {
    data: userData,
    isLoading: isUserLoading,
    error: userError,
  } = useGetUserQuery(userId, {
    skip: !userId || !userFormExpanded,
  });

  const typedUserError = userError as ApiErrorPayload | undefined;
  const dataErrorMessage = t('errorCommon');
  const userErrorMessage =
    typedUserError && typeof typedUserError.data?.detail === 'string'
      ? typedUserError.data.detail
      : dataErrorMessage;

  if (Number.isNaN(userId)) return null;

  return (
    <>
      <>
        <ButtonBack path={paths.configuration} query={{ selectedItem: ConfiguredItem.USERS }}>
          {t('buttonBackToConfigurationList')}
        </ButtonBack>
        <GapY size={4} />
      </>

      <Grid container spacing={{ xs: 4, lg: 10 }} px={0} pt={0}>
        <Grid item xs={12} md={12} lg={12} xl={5} pt={0}>
          <Accordion
            expanded={userFormExpanded}
            onChange={() => setUserFormExpanded(!userFormExpanded)}
            sx={{ background: palette.common.white }}
            elevation={0}
          >
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
              <CenterX sx={{ gap: 2 }}>
                <Typography>{t('headingUserDetails')}</Typography>
                {userData && !userData.is_active && (
                  <Chip label={t('statusUserDeactivated')} color="error" size="small" />
                )}
              </CenterX>
            </AccordionSummary>
            <AccordionDetails>
              {isUserLoading && <CircularProgress />}
              {userError && !isUserLoading ? (
                <Alert severity="error">{userErrorMessage}</Alert>
              ) : null}
              {userData && !isUserLoading && !userError && (
                <UpdateUserForm userId={userId} userData={userData} />
              )}
            </AccordionDetails>
          </Accordion>
        </Grid>

        <Grid item xs={12} md={12} lg={12} xl={7}>
          <SiteList userData={userData} onAddRelatedSite={() => setSiteRelationModalOpen(true)} />
        </Grid>
      </Grid>

      <SiteRelationModal open={siteRelationModalOpen} setOpen={setSiteRelationModalOpen} />
    </>
  );
};

export default UpdateUserPage;
