import { TextField, TextFieldProps } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

type DatePickerFieldProps = Omit<TextFieldProps, 'onChange' | 'value'> & {
  label: string;
  onChange: (val: Nullable<Date>) => void;
  minDate?: Date;
  maxDate?: Date;
  value?: Nullable<Date>;
  disableFuture?: boolean;
};

export const DatePickerField = ({
  label,
  onChange,
  minDate,
  maxDate,
  value,
  size,
  disableFuture,
  ...textProps
}: DatePickerFieldProps) => (
  <DatePicker
    slotProps={{ textField: { size, ...textProps } }}
    slots={{ textField: TextField }}
    label={label}
    value={value ?? null}
    onChange={(newValue) => onChange(newValue ?? null)}
    format="yyyy-MM-dd"
    minDate={minDate}
    maxDate={maxDate}
    disableFuture={disableFuture}
    views={['year', 'month', 'day']}
  />
);
