import { AppEnvironment, FEATURE_FLAGS } from '@appTypes/common';
import { featureFlags } from '@settings/featureFlags';
import { getEnvironment } from '@utils/utils';
import { storage } from './localStorage';

const FEATURE_FLAGS_KEY = 'featureFlags';

export const isFeatureFlagEnabled = (flagName: FEATURE_FLAGS): boolean => {
  const storedFlag = storage.get<boolean>(`${FEATURE_FLAGS_KEY}.${flagName}`);
  return storedFlag !== null ? storedFlag : false;
};

export const enableFeatureFlag = (flagName: FEATURE_FLAGS): void => {
  storage.set(`${FEATURE_FLAGS_KEY}.${flagName}`, true);
};

export const disableFeatureFlag = (flagName: FEATURE_FLAGS): void => {
  storage.set(`${FEATURE_FLAGS_KEY}.${flagName}`, false);
};

export const getVisibleFeatureFlags = () =>
  featureFlags.filter(({ environments }) => environments.includes(getEnvironment()));

export const isFeatureFlagActiveInCurrentEnv = (flagName: FEATURE_FLAGS) => {
  const featureFlagConfig = featureFlags.find((featureFlag) => featureFlag.name === flagName);
  const currentEnv = getEnvironment();
  return featureFlagConfig?.environments.includes(currentEnv as AppEnvironment);
};
